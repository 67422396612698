import { clear } from 'idb-keyval';

export async function handlePostRequest(event): Promise<Response> {
    const request = event.request;
    const cacheUrl = new URL(request.url);

    const cacheKey = new Request(cacheUrl.toString(), {
        headers: request.headers,
        method: 'GET'
    });

    const cache = await caches.open('api');

    let response = await cache.match(cacheKey);

    if (!response) {
        response = await fetch(request);
        event.waitUntil(cache.put(cacheKey, response.clone()));
    }
    return response;
}

export const clearCache = async (): Promise<void> => {
    const cacheNames = await caches.keys();
    await Promise.all(cacheNames.map(async (cacheName) => caches.delete(cacheName)));
    await clear();
    window.location.reload();
};

import dayjs from 'dayjs';

import { clearSurveyTokenCookie } from '@webapp/common/lib/cookies';
import { stringNotEmpty } from '@webapp/common/lib/utils';
import { CurrentSection, QuestionType, WelcomePageType, withClickReply } from '@webapp/common/resources/survey';

import { SurveyBundleFinishType, SurveyBundleType } from './bundle';
import type { ISurveyBundleModel } from './bundle';
import { mergeFetchStatus } from './helpers/common';

export const views = (self: any): any => ({
    get authenticated(): boolean {
        return Boolean(
            self.tokenData &&
                (self.tokenData.private === 0 ||
                    (self.tokenData.private.password === 0 && self.tokenData.private.vkAuth === 0))
        );
    },
    get authenticatedVk(): boolean {
        return Boolean(self?.tokenData?.private?.vkAuth === 0);
    },
    get authenticatedPwd(): boolean {
        return Boolean(self?.tokenData?.private?.password === 0);
    },
    get withPassword(): boolean {
        const { individualPasswords, passwordAccess } = self.info.params.main;
        return passwordAccess || individualPasswords;
    },
    get askPassword(): boolean {
        return self.withPassword && !self.authenticatedPwd;
    },
    get askVkAuth(): boolean {
        const { authenticatedPwd, authenticatedVk, info, withPassword } = self;
        const { vkAuth } = info.params.main;
        return vkAuth && ((withPassword && authenticatedPwd) || (!withPassword && !authenticatedVk));
    },
    get currentBundle(): ISurveyBundleModel {
        if (self.bundles.length === 0) {
            return null;
        }
        return self.bundles[self.currentBundleIndex];
    },
    get firstInvalidId() {
        if (!self.currentBundle) return null;

        const firstInvalid = self.currentBundle.questions.find((q) => q.invalid || q.commentInvalid);
        return firstInvalid ? firstInvalid.id : null;
    },
    get isFirstPage() {
        return self.currentBundleIndex === 0;
    },
    get showIndicator() {
        const {
            currentSection,
            info: {
                params: {
                    other: { indicator }
                }
            },
            timerEnds
        } = self;

        // TODO untangle, refactor
        const enabled = Boolean(indicator || timerEnds);
        const nextFinal =
            self?.nextBundle?.finishType === SurveyBundleFinishType.STOP ||
            self?.nextBundle?.finishType === SurveyBundleFinishType.DISQUAL ||
            self?.nextBundle?.finishType === SurveyBundleFinishType.TEXT;
        const currentFinal =
            self?.currentBundle?.finishType === SurveyBundleFinishType.STOP ||
            self?.currentBundle?.finishType === SurveyBundleFinishType.DISQUAL ||
            self?.currentBundle?.finishType === SurveyBundleFinishType.TEXT;
        const satisfyPage = currentSection === CurrentSection.BUNDLES && !currentFinal;
        const noNext = self.nextBundle === null;
        const singlePage = !self.isFirstPage && noNext && self.bundles.length === 1 && !currentFinal;
        const lastPageInRow = self.currentBundleIndex === self.bundles.length - 1 && noNext && !currentFinal;

        if (lastPageInRow && currentSection === CurrentSection.FINISH) {
            clearSurveyTokenCookie();
        }

        return enabled && satisfyPage && (!singlePage || nextFinal || lastPageInRow);
    },
    get prevBundle() {
        if (self.bundles.length === 0 || self.isFirstPage) {
            return null;
        }

        return self.bundles[self.currentBundleIndex - 1];
    },
    get nextBundle() {
        if (self.bundles.length === 0 || self.bundles.length === self.currentBundleIndex + 1) {
            return null;
        }
        return self.bundles[self.currentBundleIndex + 1];
    },
    get redirecting() {
        // TODO refactor, unclear logic
        return Boolean(
            self.nextBundle &&
                [SurveyBundleType.REDIRECT_SURVEY, SurveyBundleType.REDIRECT_URL].includes(self.nextBundle.type)
        );
    },
    get startButtonVisible() {
        return self.currentSection === CurrentSection.START;
    },
    get canGoBack() {
        if (
            self.currentSection !== CurrentSection.BUNDLES ||
            !self.currentBundle ||
            self.currentBundle.type !== SurveyBundleType.QUESTIONS
        ) {
            return false;
        }

        return !!self.prevBundle;
    },
    get nextButtonVisible() {
        if (
            self.currentSection !== CurrentSection.BUNDLES ||
            !self.currentBundle ||
            self.currentBundle.type !== SurveyBundleType.QUESTIONS
        ) {
            return false;
        }

        return self.nextBundle && self.nextBundle.type === SurveyBundleType.QUESTIONS;
    },
    get sendButtonVisible() {
        if (
            self.currentSection !== CurrentSection.BUNDLES ||
            !self.currentBundle ||
            self.currentBundle.type !== SurveyBundleType.QUESTIONS
        ) {
            return false;
        }

        return !self.nextBundle || self.nextBundle.type !== SurveyBundleType.QUESTIONS;
    },
    get initStatus() {
        return mergeFetchStatus(self.infoFetchingStatus, self.contentFetchingStatus);
    },
    get startStatus() {
        return mergeFetchStatus(self.infoFetchingStatus, self.contentFetchingStatus);
    },
    get progress() {
        const { questions, sendButtonVisible } = self;
        const nextFinal =
            self.nextBundle === null ||
            self?.nextBundle?.finishType === SurveyBundleFinishType.STOP ||
            self?.nextBundle?.finishType === SurveyBundleFinishType.DISQUAL ||
            self?.nextBundle?.finishType === SurveyBundleFinishType.TEXT;
        const currentFinal = self?.currentBundle?.finishType === SurveyBundleFinishType.STOP;
        const willStop = nextFinal || currentFinal;
        const eligibleQuestions = questions.filter(({ type }) => type !== QuestionType.TEXT_BLOCK);
        const changedQuestions = eligibleQuestions.filter(({ wasChanged }) => wasChanged);

        return willStop && sendButtonVisible ? 1 : changedQuestions.length / eligibleQuestions.length;
    },
    get invalidQuestionsQuantity() {
        return self.questions.reduce((acc, { invalid }) => acc + (invalid ? 1 : 0), 0);
    },
    get timeAgo() {
        const now = dayjs();
        const {
            main: { timerHours, timerMinutes, timerSeconds }
        } = self.info.params;
        const allTime = now.add(timerHours, 'h').add(timerMinutes, 'm').add(timerSeconds, 's');

        if (!self.timeLeft) {
            // TODO strict check
            // fixes reading value if timer not set
            return dayjs.duration(0);
        }

        const endTime = now
            .add(self.timeLeft.hours(), 'h')
            .add(self.timeLeft.minutes(), 'm')
            .add(self.timeLeft.seconds(), 's');

        return dayjs.duration(allTime.diff(endTime));
    },
    get welcomePage() {
        return self.info.params.main.welcomePage;
    },
    get saveAtFinish() {
        return !self.info.params.main.savingNotComplete;
    },
    get withRandom() {
        return self.pages.some((p) => p.random);
    },
    get showWelcomeText() {
        const {
            currentSection,
            info: {
                params: {
                    main: { welcomePage, welcomePageText }
                }
            },
            isFirstPage
        } = self;
        const firstPage = isFirstPage && currentSection === CurrentSection.BUNDLES;
        return Boolean(
            welcomePageText &&
                welcomePage === WelcomePageType.TO_SURVEY_PAGE &&
                currentSection !== CurrentSection.TEST_RESULT &&
                firstPage
        );
    },
    get isStartPage() {
        const { currentSection } = self;
        return currentSection === CurrentSection.START;
    },
    get showWelcomePage() {
        return self.showWelcomeText || self.isStartPage;
    },
    get dontShowNextButton() {
        const {
            currentBundle,
            info: {
                params: {
                    other: { clickReply }
                }
            }
        } = self;
        return Boolean(
            currentBundle &&
                clickReply &&
                currentBundle.questions.every(
                    ({ anyNotEmptyAnswer, commentText, params: { comment, commentRequired }, type }) => {
                        const emptyComment = comment && commentRequired && !stringNotEmpty(commentText);
                        const hideByEmptyAnswer = !anyNotEmptyAnswer || emptyComment;

                        return withClickReply(type) && hideByEmptyAnswer;
                    }
                )
        );
    }
});

import type { ISimpleType } from 'mobx-state-tree';
import { types } from 'mobx-state-tree';

export function NEnumeration(options: AnyObject<string, number>): ISimpleType<number> {
    return types.union(...Object.values(options).map((o) => types.literal(o)));
}

export function Enumeration<T extends string>(e: AnyObject<T>): ISimpleType<T> {
    return types.union(...Object.values(e).map((option) => types.literal(option)));
}

import { getParent, getParentOfType, types } from 'mobx-state-tree';
import type { Instance } from 'mobx-state-tree';

import { getApiClass } from '../survey';

import { SurveyQuestionModel } from './question';
import { SurveyQuestionAnswerModel } from './question_answer';

export const SurveyQuestionResponseModel = types
    .model('survey_question_response', {
        value: types.maybeNull(types.union(types.boolean, types.string, types.number)),
        extra: types.maybeNull(types.union(types.boolean, types.string, types.number)),
        comment: types.maybeNull(types.string),
        commentInvalid: types.optional(types.boolean, false)
    })
    .views((self) => ({
        get question(): any {
            return getParentOfType(self, SurveyQuestionModel);
        },
        get data(): any {
            const { comment, extra, value } = self;
            return {
                value,
                extra,
                comment
            };
        }
    }))
    .views((self) => ({
        get answer(): any {
            const parent = getParent<any>(self);

            return SurveyQuestionAnswerModel.is(parent) ? parent : parent.answer;
        },
        get group(): any {
            return getParent<any>(self, 3);
        }
    }))
    .actions((self) => ({
        change(value: PrimitiveValue): void {
            const { params, timerTime, type } = self.question;

            if (params.timer && timerTime <= 0) {
                console.error('FIXME timerTime is unset for some reason');
                return;
            }
            getApiClass(type).change(self.question, self as ISurveyQuestionResponseModel, value);
            self.question.changed();
            self.question.saveIncompleteResponse();
            self.question.getQuotas();
        },
        changeExtra(value: string): void {
            getApiClass(self.question.type).changeExtra(self.question, self as ISurveyQuestionResponseModel, value);
            self.question.saveIncompleteResponse();
            self.question.getQuotas();
        },
        setValue(value): void {
            self.value = value;
        },
        setExtra(value): void {
            self.extra = value;
        },
        setComment(value: string): void {
            self.comment = value;
            self.question.saveIncompleteResponse();
        },
        setCommentInvalid(value: boolean): void {
            self.commentInvalid = value;
        }
    }));

export type ISurveyQuestionResponseModel = Instance<typeof SurveyQuestionResponseModel>;

import type { Instance } from 'mobx-state-tree';
import { types } from 'mobx-state-tree';

import { initialFonts } from '@webapp/account/src/resources/survey/addons/fonts';
import { initialView } from '@webapp/account/src/resources/survey/addons/view';

const BrandModel = types.model('survey_info_design_brand', {
    background: types.maybeNull(types.string),
    backgroundColor: types.maybeNull(types.string),
    backgroundGradient: types.maybeNull(types.string),
    backgroundImage: types.maybeNull(types.string),
    backgroundTransparent: types.optional(types.number, 0),
    headerType: types.maybeNull(types.string),
    headerSize: types.optional(types.number, 0),
    headerBackgroundColor: types.maybeNull(types.string),
    headerBackgroundImage: types.maybeNull(types.string),
    headerBackgroundTransparent: types.optional(types.number, 50),
    headerStick: types.optional(types.boolean, false),
    headerAllPage: types.optional(types.boolean, true),
    logo: types.maybeNull(types.string),
    logoPos: types.maybeNull(types.string),
    headerTitlePosition: types.maybeNull(types.string),
    logoStyle: types.maybeNull(types.string),
    titleStyle: types.maybeNull(types.string)
});

const ButtonsModel = types.model('survey_info_design_buttons', {
    replyButtonLabel: types.optional(types.string, 'Ответить'),
    sendButtonLabel: types.optional(types.string, 'Отправить'),
    backButtonLabel: types.optional(types.string, 'Назад'),
    nextButtonLabel: types.optional(types.string, 'Далее')
});

const CssModel = types.model('survey_info_design_css', {
    text: types.maybeNull(types.string)
});

const FontsModel = types
    .model('survey_info_design_fonts', {
        fontFamily: types.maybeNull(types.string),
        titleSize: types.optional(types.number, initialFonts.titleSize),
        titleColor: types.maybeNull(types.string),
        titleBold: types.optional(types.boolean, false),
        titleItalic: types.optional(types.boolean, false),
        titleUnderline: types.optional(types.boolean, false),
        titleThrough: types.optional(types.boolean, false),
        questionTextSize: types.optional(types.number, initialFonts.questionTextSize),
        questionTextColor: types.maybeNull(types.string),
        questionTextBold: types.optional(types.boolean, false),
        questionTextItalic: types.optional(types.boolean, false),
        questionTextUnderline: types.optional(types.boolean, false),
        questionTextThrough: types.optional(types.boolean, false),
        replyTextSize: types.optional(types.number, initialFonts.replyTextSize),
        replyTextColor: types.maybeNull(types.string),
        replyTextBold: types.optional(types.boolean, false),
        replyTextItalic: types.optional(types.boolean, false),
        replyTextUnderline: types.optional(types.boolean, false),
        replyTextThrough: types.optional(types.boolean, false),
        elementColor: types.maybeNull(types.string),
        fieldColor: types.maybeNull(types.string),
        fontColor: types.maybeNull(types.string),
        buttonColor: types.maybeNull(types.string),
        buttonTextColor: types.maybeNull(types.string),
        fieldBorderColor: types.maybeNull(types.string)
    })
    .preProcessSnapshot((ss) => ({
        ...ss,
        // TODO fix backend core#282
        questionTextSize: parseInt(String(ss.questionTextSize), 10),
        replyTextSize: parseInt(String(ss.replyTextSize), 10),
        titleSize: parseInt(String(ss.titleSize), 10)
    }));

export const ViewModel = types.model('survey_info_design_view', {
    lineHeight: types.optional(types.number, initialView.lineHeight),
    gap: types.optional(types.number, initialView.gap),
    questionBlockPosition: types.maybeNull(types.string),
    buttonBlockPosition: types.maybeNull(types.string),
    paddingLeft: types.optional(types.number, initialView.paddingLeft),
    paddingRight: types.optional(types.number, initialView.paddingRight),
    paddingTop: types.optional(types.number, initialView.paddingTop),
    paddingBottom: types.optional(types.number, initialView.paddingBottom)
});

export const SurveyInfoDesignModel = types.model('survey_info_design', {
    brand: types.optional(BrandModel, {}),
    buttons: types.optional(ButtonsModel, {}),
    css: types.optional(CssModel, {}),
    fonts: types.optional(FontsModel, {}),
    view: types.optional(ViewModel, {})
});

export type ISurveyInfoDesignModel = Instance<typeof SurveyInfoDesignModel>;

import { useEffect, useState } from 'react';

const browser = typeof window !== 'undefined';

export const ttDevMode = (): boolean => browser && window.ttDevMode;

declare global {
    interface Window {
        ttDevMode: boolean;
    }
}

let registered = false;

export const useDevMode = (): boolean => {
    const [dev, setDev] = useState(ttDevMode());

    const handler = (e: KeyboardEvent): void => {
        if (typeof e.getModifierState === 'undefined') return;
        const mods = e.getModifierState('Shift') && e.getModifierState('Control');
        if (browser && mods && e.key === '*') {
            window.ttDevMode = !window.ttDevMode;
            e.stopPropagation();
            e.preventDefault();
            document.body.classList.toggle('ttDev');
            setDev(window.ttDevMode);
        }
    };

    useEffect(() => {
        if (registered) {
            console.error('useDevMode::dont call twice');
            return;
        }

        registered = true;
        browser && document.addEventListener('keydown', handler);

        return () => {
            browser && document.removeEventListener('keydown', handler);
            registered = false;
        };
    }, []);

    return dev;
};

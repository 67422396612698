import { getParentOfType, types } from 'mobx-state-tree';
import type { Instance } from 'mobx-state-tree';

import { SurveyQuestionModel } from './question';
import type { ISurveyQuestionAnswerModel } from './question_answer';
import { SurveyQuestionResponseModel } from './question_response';

export const GroupResponse = types
    .model({
        answerId: types.identifierNumber,
        response: types.optional(
            types.late(() => SurveyQuestionResponseModel),
            {}
        )
    })
    .views((self) => ({
        get answer(): ISurveyQuestionAnswerModel {
            const question = getParentOfType(self, SurveyQuestionModel);

            if (!question.answers) {
                return null;
            }

            return question.answers.find((answer) => answer.id === self.answerId);
        }
    }));

export const SurveyQuestionGroupModel = types
    .model('survey_question_group', {
        id: types.identifierNumber,
        name: types.maybeNull(types.string),
        type: types.maybeNull(types.number),
        order: types.maybeNull(types.number),
        numericValue: types.maybeNull(types.number),
        exception: types.optional(types.boolean, false),
        responses: types.map(GroupResponse),
        answers: types.maybeNull(types.array(types.string))
    })
    .views((self) => ({
        getResponse(answerId: number) {
            if (self.responses.has(answerId as any)) {
                return self.responses.get(answerId as any).response;
            }

            return null;
        },
        getResponsesValues() {
            return Object.values(self.responses.toJSON()).map(({ response: { value } }) => value);
        }
    }))
    .actions((self) => ({
        getResponseOrCreate(answerId) {
            let response = self.getResponse(answerId);

            if (!response) {
                self.responses.set(answerId, { answerId });
                response = self.getResponse(answerId);
            }

            return response;
        }
    }))
    .actions((self) => ({
        changeResponseValue(answerId: number, value) {
            self.getResponseOrCreate(answerId).change(value);
        },
        changeResponseExtra(answerId: number, value) {
            self.getResponseOrCreate(answerId).changeExtra(value);
        },
        setResponse(key: string, value: any) {
            self.responses.set(key, value);
        },
        deleteResponse(key: string) {
            if (self.responses.has(key)) {
                self.responses.delete(key);
            }
        }
    }));

export type ISurveyQuestionGroupModel = Instance<typeof SurveyQuestionGroupModel>;

import { replaceMask } from '@webapp/common/lib/ui';
import { CANTRATE_ID } from '@webapp/common/resources/mst-survey/api/constants';
import type { AnswersRequest } from '@webapp/common/resources/mst-survey/api/queries-api';
import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import { getApiClass, QuestionType } from '@webapp/common/resources/survey';

export const replaceMaxSize = (s: string, v: string): string => replaceMask({ max: v }, s);
export const replaceFileExt = (s: string, v: string): string => replaceMask({ formats: v }, s);
export const replaceMaxCount = (s: string, v: number): string => replaceMask({ count: v }, s);

type ISavedQuestion = Pick<
    ISurveyQuestionModel,
    'id' | 'answers' | 'commentText' | 'groups' | 'response' | 'wasChanged' | 'expired'
>;

export const mapQuestionForSnapshot = ({
    answers,
    commentText,
    expired,
    groups,
    id,
    response,
    wasChanged
}: ISurveyQuestionModel): ISavedQuestion => ({
    id,
    answers,
    commentText,
    groups,
    response,
    expired,
    wasChanged
});

export const mapQuestionForQueue = (q: ISurveyQuestionModel): AnswersRequest => {
    const api = getApiClass(q.type);
    return api.query(q);
};

export const fillQuestions = (questions, details) => {
    if (!details) return;

    const questionsObj = details.reduce((acc, item) => {
        if (acc[item.question_id]) {
            acc[item.question_id].push(item);
        } else {
            acc[item.question_id] = [item];
        }

        return acc;
    }, {});

    questions.forEach((question) => {
        const findInSaved = questionsObj[question.id];
        const { type } = question;

        if (findInSaved) {
            if (findInSaved[0].cant_rate) {
                if (type === QuestionType.SMILE) {
                    question.answers?.[0]?.response.change(CANTRATE_ID);
                    return;
                }

                if (type === QuestionType.NPS || type === QuestionType.SCALE || type === QuestionType.STAR) {
                    question.response.change(CANTRATE_ID);
                    return;
                }
            }

            switch (type) {
                case QuestionType.NPS:
                case QuestionType.NUMBER:
                case QuestionType.STAR: {
                    question.response.change(findInSaved[0].answer_int);
                    return;
                }

                case QuestionType.CSI:
                case QuestionType.MATRIX_RATING:
                case QuestionType.RANK:
                case QuestionType.TEST_RANK:
                case QuestionType.DISTRIBUTE_SCALE: {
                    question.answers.forEach((answer) => {
                        const answersInSaved = findInSaved.filter((item) => item.answer_id === answer.id);

                        if (answersInSaved[0]?.answer_int) {
                            answer.response.change(answersInSaved[0]?.answer_int);
                        }

                        if (answersInSaved[1]?.answer_int) {
                            answer.response.changeExtra(findInSaved[1].answer_int);
                        }

                        if (answersInSaved[0]?.cant_rate) {
                            answer.response.change(CANTRATE_ID);
                        }
                    });
                    return;
                }

                case QuestionType.ONE_OF_LIST:
                case QuestionType.FEW_OF_LIST:
                case QuestionType.SELECT_FEW_IMAGE:
                case QuestionType.SELECT_ONE_IMAGE:
                case QuestionType.TEST_ONE_OF_LIST:
                case QuestionType.TEST_FEW_OF_LIST: {
                    question.answers.forEach((answer) => {
                        const findAnswer = findInSaved.find((item) => item.answer_id === answer.id);

                        if (findAnswer?.answer_bool) {
                            answer.response.change(Boolean(findAnswer?.answer_bool));
                        }

                        if (findAnswer?.answer_text) {
                            answer.response.changeExtra(findAnswer?.answer_text);
                        }
                    });
                    return;
                }

                case QuestionType.NAME: {
                    question.groups.forEach((group) => {
                        const answerInSaved = findInSaved.find((item) => item.answer_group_id === group.id);

                        if (answerInSaved) {
                            group.changeResponseValue(answerInSaved.answer_id, answerInSaved.answer_str);
                        }
                    });
                    return;
                }

                case QuestionType.SEM_DIFF: {
                    question.groups.forEach((group) => {
                        const answerInSaved = findInSaved.find((item) => item.answer_group_id === group.id);

                        if (answerInSaved) {
                            group.changeResponseValue(answerInSaved.answer_id, true);
                        }
                    });
                    return;
                }

                case QuestionType.MATRIX_TEXT_ANSWER:
                case QuestionType.MATRIX_DROPDOWN_LIST: {
                    question.groups.forEach((group) => {
                        const groupsInSaved = findInSaved.filter((item) => item.answer_group_id === group.id);

                        question.answers.forEach((answer) => {
                            const findAnswer = groupsInSaved.find((item) => item.answer_id === answer.id);

                            if (findAnswer) {
                                group.changeResponseValue(findAnswer.answer_id, findAnswer.answer_str);
                            }
                        });
                    });
                    return;
                }

                case QuestionType.MATRIX_SINGLE_ANSWER:
                case QuestionType.MATRIX_FEW_ANSWERS: {
                    question.groups.forEach((group) => {
                        const groupsInSaved = findInSaved.filter((item) => item.answer_group_id === group.id);

                        question.answers.forEach((answer) => {
                            const findAnswer = groupsInSaved.find((item) => item.answer_id === answer.id);

                            if (findAnswer) {
                                group.changeResponseValue(findAnswer.answer_id, true);
                            }

                            if (findAnswer?.answer_str) {
                                group.changeResponseExtra(findAnswer.answer_id, findAnswer?.answer_str);
                            }
                        });
                    });
                    return;
                }

                case QuestionType.DROPDOWN_LIST: {
                    question.response.change(findInSaved[0].answer_id);
                    return;
                }

                case QuestionType.SHORT_TEXT:
                case QuestionType.TEST_TEXT: {
                    question.response.change(findInSaved[0].answer_str);
                    return;
                }

                case QuestionType.LONG_TEXT: {
                    question.response.change(findInSaved[0].answer_text);
                    return;
                }

                case QuestionType.AGREEMENT: {
                    question.response.change(Boolean(findInSaved[0].answer_bool));
                    return;
                }

                case QuestionType.PHONE: {
                    question.answers.forEach((answer) => {
                        const answerInSaved = findInSaved.find((item) => item.answer_id === answer.id);

                        if (answerInSaved) {
                            answer.response.change(answerInSaved.answer_str);
                            answer.response.changeExtra(answerInSaved.answer_text);
                        }
                    });
                    return;
                }

                case QuestionType.RESIDENCE:
                case QuestionType.NESTED_LIST: {
                    question.answers.forEach((answer) => {
                        const answerInSaved = findInSaved.find((item) => item.answer_id === answer.id);

                        if (answerInSaved) {
                            answer.response.change(Number(answerInSaved.answer_str));
                            answer.response.changeExtra(answerInSaved.answer_text);
                        }
                    });
                    return;
                }

                case QuestionType.EMAIL:
                case QuestionType.DATETIME:
                case QuestionType.SMILE:
                case QuestionType.GROUP_FREE_ANSWERS: {
                    question.answers.forEach((answer) => {
                        const answerInSaved = findInSaved.find((item) => item.answer_id === answer.id);

                        if (answerInSaved) {
                            answer.response.change(answerInSaved.answer_str);
                        }
                    });
                    return;
                }

                case QuestionType.SCALE: {
                    question.response.change(findInSaved[0].answer_group_id);
                    return;
                }
            }
        }
    });
};

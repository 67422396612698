import cn from 'classnames';

import { BoldCross, Cross } from '@webapp/ui/lib/icons';

import css from './close-btn.css';

export interface CloseButton {
    onClick: ReactMouseEventHandler;
    className?: string;
}

export const DialogCloseBtn: VFC<
    CloseButton & {
        style?: CSSProperties;
        iconStyle?: CSSProperties;
        iconClassName?: string;
    }
> = ({ className, iconClassName, iconStyle, onClick, style }) => (
    <div className={cn(css.closeBtnBase, css.modalCloseBtn, className)} style={style} onClick={onClick}>
        <Cross className={cn(css.icon, iconClassName)} style={iconStyle} />
    </div>
);

export const InlineCloseBtn: VFC<CloseButton> = ({ className, onClick }) => (
    <BoldCross className={cn(css.closeBtnBase, css.inlineCloseBtn, className)} onClick={onClick} />
);

export const CloseBtn: VFC<CloseButton> = ({ className, onClick }) => (
    <BoldCross className={cn(css.closeBtnBase, css.closeBtn, className)} onClick={onClick} />
);

export const CloseRedBtn: VFC<CloseButton> = ({ className, onClick }) => (
    <BoldCross className={cn(css.closeRedBtn, className)} onClick={onClick} />
);

import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';

import { useClickOutside } from '@webapp/common/hooks/use-click-outside';
import { useToggle } from '@webapp/common/hooks/use-toggle';
import { useWorkbox } from '@webapp/common/hooks/use-workbox';
import { isBrowser } from '@webapp/common/lib/const';
import { clearCache } from '@webapp/common/lib/offline-cache';
import { fileSize } from '@webapp/common/lib/ui';
import { getOfflineAnswersCount, getOfflineStats, surveyApi } from '@webapp/common/resources/mst-survey/survey/api';
import { useStore } from '@webapp/survey/src/store';
import { Button } from '@webapp/ui/lib/button';
import { CloseBtn } from '@webapp/ui/lib/close-btn';
import { Gear } from '@webapp/ui/lib/icons';
import { RestartButton } from '@webapp/ui/lib/misc';

import css from './offline.css';

export const OfflineTools = observer(() => {
    const { survey } = useStore();
    const [diskLeft, setDiskLeft] = useState(0);
    const [local, setLocal] = useState(0);
    const [sent, setSent] = useState(0);
    const [total, setTotal] = useState(0);
    const [syncStarted, setSyncStarted] = useState(false);

    const { enabled, off, toggle } = useToggle();

    const ref = useClickOutside(off);
    const { install, online } = useWorkbox(true);

    const updateApp = useCallback(() => {
        if (confirm('Будет установлена новая версия опроса. Продолжить?')) {
            void clearCache();
        }
    }, []);

    const sendAnswers = useCallback(() => {
        void (async () => {
            // TODO move to model
            setSyncStarted(true);
            await surveyApi.sendOfflineAnswers(survey.toJSON());
            setSyncStarted(false);
        })();
    }, [survey]);

    useEffect(() => {
        if (isBrowser) {
            void (async () => {
                const r = await Promise.all([
                    navigator.storage.estimate(),
                    getOfflineAnswersCount(),
                    getOfflineStats()
                ]);

                const [{ quota, usage }, local, { sent, total }] = r;
                setDiskLeft(quota - usage);
                setLocal(local);
                setSent(sent);
                setTotal(total);
            })();
        }
    });

    return (
        <div className={css.tools} ref={ref} title={online ? 'в сети' : 'нет сети'}>
            <Gear className={cn(css.gear, online && css.online)} onClick={toggle} />
            {install && (
                <>
                    <div className={css.popupBg} />
                    <div className={css.installPopup}>
                        <Button bordered slim className={css.installButton} onClick={install}>
                            Установить
                        </Button>
                    </div>
                </>
            )}
            {enabled && (
                <ul className={css.content}>
                    <li>
                        <RestartButton />
                    </li>
                    <li>
                        <ul>
                            <li>Свободно на диске: {fileSize(diskLeft)}</li>
                            <li>Собрано: {total}</li>
                            <li>Не отправлено: {local}</li>
                            <li>Отправлено: {sent}</li>
                        </ul>
                    </li>
                    <li>
                        <Button
                            bordered
                            slim
                            disabled={!online || syncStarted}
                            loading={syncStarted}
                            onClick={sendAnswers}
                        >
                            Отправить
                        </Button>
                    </li>
                    <li>
                        <input className={css.statToggle} id='statToggle' type='checkbox' />
                        <label htmlFor='statToggle'>Дополнительно</label>
                        <ul>
                            <li>
                                <Button bordered slim disabled={!online} onClick={updateApp}>
                                    Обновить
                                </Button>
                            </li>
                        </ul>
                    </li>
                    <CloseBtn className={css.close} onClick={off} />
                </ul>
            )}
        </div>
    );
});
OfflineTools.displayName = 'OfflineTools';

import cloneDeep from 'lodash/cloneDeep';

import type { LogicPageQuestion } from './logic';

export interface Page {
    id: number;
    name: string;
    fresh: boolean; // just added
    expand: boolean;
    onebyone: boolean;
    random: boolean;
    randomCount: number;
    questionCount: number;
    questions: Array<LogicPageQuestion>;
    order: number;
}

export const initialPage: Readonly<Page> = {
    id: 0,
    name: '',
    fresh: false,
    expand: false,
    onebyone: false,
    random: false,
    randomCount: null,
    questionCount: 0,
    questions: [],
    order: 0
};

export const mapPage = ({
    ID,
    QUESTION_COUNT,
    QUESTION_RANDOM_ORDERING,
    QUESTION_SP,
    TITLE,
    TOTAL_QUESTION,
    ORDER
}: {
    ID: number;
    TITLE: string;
    TOTAL_QUESTION: number;
    QUESTION_COUNT: number | null;
    QUESTION_RANDOM_ORDERING: number;
    QUESTION_SP: number;
    ORDER: number;
}): Page => ({
    ...cloneDeep(initialPage),
    id: ID,
    name: TITLE,
    random: Boolean(QUESTION_RANDOM_ORDERING),
    randomCount: QUESTION_COUNT || 0,
    questionCount: TOTAL_QUESTION,
    expand: true,
    onebyone: Boolean(QUESTION_SP),
    questions: [],
    order: ORDER
});

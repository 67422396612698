import type { Instance } from 'mobx-state-tree';
import { types } from 'mobx-state-tree';

const ParamsAlertModel = types.model('survey_info_params_alert', {
    required: types.maybeNull(types.string),
    amount: types.maybeNull(types.string),
    matrixAmount: types.maybeNull(types.string),
    numRange: types.maybeNull(types.string),
    indicator: types.maybeNull(types.string),
    fileMaxSize: types.maybeNull(types.string),
    fileExt: types.maybeNull(types.string),
    fileMaxAmount: types.maybeNull(types.string),
    comment: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    datetime: types.maybeNull(types.string),
    distribution: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    yourAnswer: types.maybeNull(types.string)
});

const ParamsMainModel = types.model('survey_info_params_main', {
    audio: types.maybeNull(types.boolean),
    welcomePage: types.maybeNull(types.string),
    welcomePageText: types.maybeNull(types.string),
    completePage: types.maybeNull(types.string),
    completePageText: types.maybeNull(types.string),
    completeWebsite: types.maybeNull(types.string),
    completeSurvey: types.maybeNull(types.number),
    completeSurveyUrl: types.maybeNull(types.string),
    disabledSurveyText: types.maybeNull(types.string),
    savingNotComplete: types.maybeNull(types.boolean),
    vkAuth: types.optional(types.boolean, false),
    passwordAccess: types.optional(types.boolean, false),
    passwordLabel: types.maybeNull(types.string),
    wrongPasswordLabel: types.maybeNull(types.string),
    timer: types.optional(types.boolean, false),
    timerHours: types.maybeNull(types.number),
    timerMinutes: types.maybeNull(types.number),
    timerSeconds: types.maybeNull(types.number),
    timerOn: types.maybeNull(types.number),
    individualPasswords: types.maybeNull(types.boolean),
    passwordWasActivated: types.maybeNull(types.string),
    surveyWasBlocked: types.maybeNull(types.string),
    showResultTest: types.maybeNull(types.boolean),
    showDoneTest: types.maybeNull(types.boolean)
});

export type IParamsMainModel = Instance<typeof ParamsMainModel>;

const ParamsOtherModel = types.model('survey_info_params_other', {
    showRequiredToQuestion: types.optional(types.boolean, false),
    showQuestionNumber: types.optional(types.boolean, false),
    backToQuestion: types.optional(types.boolean, false),
    indicator: types.optional(types.boolean, false),
    indicatorPosition: types.optional(types.string, 'BOTTOM'),
    clickReply: types.optional(types.boolean, false),
    copyUrlParams: types.optional(types.boolean, false),
    ipLimiter: types.optional(types.boolean, false),
    disallowRefilling: types.optional(types.boolean, false),
    disallowTextCopy: types.optional(types.boolean, false)
});

const ParamsTestsDiapasonModel = types.model('survey_info_params_tests_diapason', {
    from: types.maybeNull(types.number),
    to: types.maybeNull(types.number),
    text: types.maybeNull(types.string)
});

const ParamsTestsModel = types.model('survey_info_params_tests', {
    maxScoreAmount: types.maybeNull(types.number),
    passingScore: types.maybeNull(types.number),
    testResultLabel: types.maybeNull(types.string),
    passingScoreLabel: types.maybeNull(types.string),
    scoreLabel: types.maybeNull(types.string),
    testPassed: types.maybeNull(types.string),
    testNotPassed: types.maybeNull(types.string),
    copyUrlParams: types.maybeNull(types.boolean),
    textDiapasons: types.maybeNull(types.array(ParamsTestsDiapasonModel))
});

export type IParamsTestsMode = Instance<typeof ParamsTestsModel>;

const ParamsShareModel = types.model('survey_info_params_share', {
    download: types.maybeNull(types.boolean),
    filters: types.maybeNull(types.boolean),
    reports: types.maybeNull(types.boolean),
    answers: types.maybeNull(types.boolean)
});

export type IParamsShareModel = Instance<typeof ParamsShareModel>;

export const SurveyInfoParamsModel = types.model('survey_info_params', {
    alert: types.optional(ParamsAlertModel, {}),
    main: types.optional(ParamsMainModel, {}),
    other: types.optional(ParamsOtherModel, {}),
    tests: types.optional(ParamsTestsModel, {}),
    share: types.optional(ParamsShareModel, {})
});

export const DetailsArray = types.model('start_response_details', {
    answer_bool: types.maybeNull(types.number),
    answer_group_id: types.maybeNull(types.number),
    answer_id: types.maybeNull(types.number),
    answer_int: types.maybeNull(types.number),
    answer_str: types.maybeNull(types.string),
    answer_text: types.maybeNull(types.string),
    comment: types.maybeNull(types.string),
    expired: types.maybeNull(types.string),
    file_id: types.maybeNull(types.number),
    question_id: types.maybeNull(types.number),
    cant_rate: types.maybeNull(types.number)
});

import type { Instance } from 'mobx-state-tree';
import { types } from 'mobx-state-tree';

export const QuestionNestedItemsModel = types.model('survey_question_nested_items', {
    id: types.identifierNumber,
    name: types.string,
    parentId: types.maybeNull(types.number),
    level: types.number,
    answerId: types.number
});

export type IQuestionNestedItemsModel = Instance<typeof QuestionNestedItemsModel>;

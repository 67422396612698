import dayjs from 'dayjs';
import type { Duration } from 'dayjs/plugin/duration';
import { cast, flow, getParentOfType, types } from 'mobx-state-tree';
import type { Instance } from 'mobx-state-tree';
import { debounce } from 'throttle-debounce';

import { humanMaxUserFileSize, isBrowser, MAX_USER_FILE_SIZE } from '@webapp/common/lib/const';
import { LocalStorage } from '@webapp/common/lib/storage';
import { fileSize } from '@webapp/common/lib/ui';
import { hasExtension } from '@webapp/common/lib/utils';
import { DurationModel } from '@webapp/common/resources/mst-types/duration';

import { AnswerType, getApiClass, withClickReply } from '../survey';

import { SurveyQuestionAnswerModel } from './question_answer';
import { SurveyQuestionGroupModel } from './question_group';
import { QuestionNestedItemsModel } from './question_items';
import { SurveyQuestionLogicModel } from './question_logic';
import { SurveyQuestionParamsModel } from './question_params';
import { SurveyQuestionResponseModel } from './question_response';
import { addDetails, surveyApi } from './survey/api';
import { mapQuestionForQueue, replaceFileExt, replaceMaxCount, replaceMaxSize } from './survey/helpers/question';
import { isOfflineMode } from './survey/lib';
import { SurveyStore } from './survey/survey';

type SavedTimer = {
    minutes: number;
    hours: number;
    seconds: number;
};

export const SurveyQuestionModel = types
    .model('survey_question', {
        id: types.identifierNumber,
        title: types.optional(types.string, ''),
        page: types.number,
        type: types.number,
        order: types.maybeNull(types.number),
        params: types.optional(
            types.late(() => SurveyQuestionParamsModel),
            {}
        ),
        answers: types.array(types.late(() => SurveyQuestionAnswerModel)),
        answeredCount: types.optional(types.number, 0),
        groups: types.array(types.late(() => SurveyQuestionGroupModel)),
        // TODO use king of generic for items
        items: types.optional(types.map(types.array(QuestionNestedItemsModel)), {}),
        logics: types.array(types.late(() => SurveyQuestionLogicModel)),
        invalid: types.optional(types.boolean, false),
        response: types.optional(
            types.late(() => SurveyQuestionResponseModel),
            {}
        ),
        filesLoading: types.optional(types.boolean, false),
        wasChanged: types.optional(types.boolean, false),
        expired: types.optional(types.boolean, false),
        errorText: types.maybeNull(types.string),
        commentText: types.maybeNull(types.string),
        commentInvalid: types.optional(types.boolean, false),
        timerTime: types.maybeNull(types.number), // TODO use duration
        timerId: types.maybeNull(types.number),
        isTestCheckedQuestion: types.optional(types.boolean, false),
        maxPoints: types.maybeNull(types.number),
        currentPoints: types.maybeNull(types.number),
        targetTime: DurationModel,
        factTime: DurationModel,
        timerIntervalId: types.maybeNull(types.number),
        shownByLogic: types.optional(types.boolean, false)
    })
    .views((self) => ({
        get survey(): any {
            return getParentOfType(self, SurveyStore);
        },
        get timerAgo(): Duration {
            const { hours, minutes, seconds } = self.params;
            const sec = 3600 * hours + 60 * minutes + seconds - self.timerTime;
            return dayjs.duration(sec, 'seconds');
        },
        get timerLeft(): Duration {
            return dayjs.duration(self.timerTime, 'seconds');
        },
        get storageTimerKey(): `question_${string}_timer` {
            return `question_${self.id}_timer`;
        },
        get selfAnswersPresentAndNotEmpty(): boolean {
            const selfAnswers = self.answers.filter((a) => a.type === AnswerType.SELF);
            const withValue = selfAnswers.some(({ response: { extra, value } }) => value && !!extra);

            return selfAnswers.length > 0 && withValue;
        }
    }))
    .views((self) => ({
        get saveIncomplete(): boolean {
            const {
                savingNotComplete,
                timer: timerForSurvey,
                timerOn: timerAfterQuestionId
            } = self.survey.info.params.main;
            return (savingNotComplete || !!timerAfterQuestionId || !!timerForSurvey) && !isOfflineMode();
        },
        get anyNotEmptyAnswer(): any {
            const regularAnswers = self.answers.filter((a) => a.type !== AnswerType.SELF);
            const withValue = regularAnswers.some(({ response: { value } }) => !!value);
            const singleResponse = self.response.value;

            return (regularAnswers.length > 0 && withValue) || self.selfAnswersPresentAndNotEmpty || singleResponse;
        }
    }))
    .actions((self) => ({
        runInAction(fn) {
            return fn();
        },
        setIsTestCheckedQuestion(checked: boolean): void {
            self.isTestCheckedQuestion = checked;
        },
        setCurrentPoints(currentPoints: number): void {
            self.currentPoints = currentPoints;
        },
        setTargetTime(targetTime: Duration): void {
            self.targetTime = targetTime;
        },
        setFactTime(factTime: Duration): void {
            self.factTime = factTime;
        },
        setMaxPoints(maxPoints: number): void {
            self.maxPoints = maxPoints;
        },
        setOrder(order: number): void {
            self.order = order;
        },
        setShownByLogic(shownByLogic: boolean): void {
            self.shownByLogic = shownByLogic;
        },
        setAnsweredCount(answeredCount: number): void {
            self.answeredCount = answeredCount;
        },
        setInvalid(invalid: boolean): void {
            self.invalid = invalid;
        },
        setFilesLoading(loading: boolean): void {
            self.filesLoading = loading;
        }
    }))
    .actions((self) => ({
        validate(): boolean {
            self.setInvalid(false);
            self.commentInvalid = false;

            const api = getApiClass(self.type);

            if (self.params.commentRequired && !self.commentText) {
                self.commentInvalid = true;
            }

            if (!api.validate(self as ISurveyQuestionModel)) {
                self.setInvalid(true);
            }

            return self.invalid || self.commentInvalid;
        },
        validateFiles(files: Array<File>): boolean {
            const { fileAmount, fileLimitExt } = self.params;
            const {
                info: {
                    params: {
                        alert: { fileExt, fileMaxAmount, fileMaxSize }
                    }
                }
            } = self.survey;

            const invalidNameFiles = [...files].filter(({ name }) => !hasExtension(name, fileLimitExt));
            const invalidSizeFiles = [...files].filter(({ size }) => size > MAX_USER_FILE_SIZE);
            const invalidCountMessage = files.length > fileAmount ? replaceMaxCount(fileMaxAmount, fileAmount) : null;

            const invalidNameMessage =
                invalidSizeFiles.length > 0
                    ? replaceMaxSize(fileMaxSize, humanMaxUserFileSize) +
                      '\n' +
                      invalidSizeFiles.map(({ name, size }) => `${name} - <b>${fileSize(size)}</b>`).join('\n')
                    : '';

            const invalidTypeMessage =
                invalidNameFiles.length > 0
                    ? replaceFileExt(fileExt, fileLimitExt.map((e) => `<b>${e}</b>`).join(', ')) +
                      '\n' +
                      invalidNameFiles
                          .map(({ name }) => `${name} - <b>${name.split('.').slice(-1)[0].toUpperCase()}</b>`)
                          .join('\n')
                    : '';

            self.errorText = [invalidCountMessage, invalidNameMessage, invalidTypeMessage].filter(Boolean).join('\n\n');

            return invalidNameFiles.length === 0 && invalidSizeFiles.length === 0 && !invalidCountMessage;
        }
    }))
    .actions((self) => ({
        changed(): void {
            self.survey.prepareBundles();

            self.wasChanged = true;
            if (self.invalid) {
                self.validate();
            }

            if (
                self.survey &&
                self.survey.info.params.other.clickReply &&
                withClickReply(self.type) &&
                !self.answers.some((a) => a.type === AnswerType.SELF)
            ) {
                self.validate();
                if (!self.invalid && !self.params.comment) {
                    // @ts-ignore
                    self.saveIncompleteResponse(true);
                    self.survey.selectNextBundle(false);
                }
            }
        },

        randomizeAnswersOrder(): void {
            self.answers = cast(
                self.answers
                    .map((a) => ({ sort: Math.random(), value: a }))
                    .sort((a, b) => a.sort - b.sort)
                    .map((a) => a.value)
            );
        },
        getQuotas: flow(function* getQuotasFn() {
            if (self.logics.some((logic) => logic.type === 4 || logic.type === 5)) {
                yield self.survey.fetchQuotas();
            }
        }),

        uploadFile: flow(function* (files: Array<File>): any {
            const filesValid = self.validateFiles(files);
            let newFiles = [];

            if (!filesValid) {
                self.setInvalid(true);
                return;
            }

            self.setFilesLoading(true);
            self.errorText = null;

            try {
                newFiles = yield surveyApi.uploadFiles(self.id, files);
            } catch (e) {
                self.setFilesLoading(false);
                self.errorText = e.message;
                return;
            }

            self.setFilesLoading(false);
            self.answers = cast(self.answers ? self.answers.concat(newFiles) : newFiles);
            self.response.change('');
        }),

        deleteFile: flow(function* (id: number): any {
            self.errorText = null;

            try {
                yield surveyApi.deleteFile(id);
            } catch (e) {
                self.errorText = e.message;
                return;
            }

            self.answers = self.answers.filter(({ file: { id: fileId } }) => fileId !== id) as any;
            self.response.change('');
        }),

        setComment(comment: string): any {
            self.commentText = comment;
            if (self.invalid || self.commentInvalid) {
                self.validate();
            }
            self.response.setComment(comment);
        },
        saveTimer(): void {
            try {
                if (self.timerLeft.as('milliseconds') > 0) {
                    LocalStorage.set(self.storageTimerKey, {
                        hours: self.timerLeft.hours(),
                        minutes: self.timerLeft.minutes(),
                        seconds: self.timerLeft.seconds()
                    });
                }
            } catch (e) {}
        }
    }))
    .actions((self) => ({
        decrementTimer(): void {
            if (self.timerTime > 0) {
                self.timerTime -= 1;
                self.saveTimer();
            }
        },

        loadTimer(): boolean {
            try {
                const { hours, minutes, seconds } = LocalStorage.get<SavedTimer>(self.storageTimerKey);
                if (isFinite(hours) && isFinite(minutes) && isFinite(seconds)) {
                    self.params.hours = hours;
                    self.params.minutes = minutes;
                    self.params.seconds = seconds;
                }

                return true;
            } catch (e) {}

            return false;
        },

        stopTimer(): void {
            clearInterval(self.timerIntervalId);
            self.timerIntervalId = null;
        }
    }))
    .actions((self) => ({
        handleInterval(): void {
            if (self.timerTime < 1) {
                self.stopTimer();
                self.runInAction(() => {
                    self.params.required = false; // skip validation to pass next step
                    self.wasChanged = true;
                    self.expired = true;
                    self.setInvalid(false);
                    self.survey.selectNextBundle(true);
                });
            } else {
                self.decrementTimer();
            }
        }
    }))
    .actions((self) => {
        const saveQuestions = () => {
            addDetails(mapQuestionForQueue(self as ISurveyQuestionModel));
            self.survey.saveQuestionsSnapshot();
        };

        const saveDebounced = debounce(300, saveQuestions);

        return {
            saveIncompleteResponse: (immediate?: boolean) => {
                if (self.saveIncomplete) {
                    if (immediate) {
                        saveQuestions();
                    } else {
                        saveDebounced();
                    }
                }
            },
            startTimer(): void {
                if (!isBrowser) return;

                self.loadTimer();

                if (self.timerTime === null) {
                    self.runInAction(() => {
                        self.timerTime = 3600 * self.params.hours + 60 * self.params.minutes + self.params.seconds;
                    });
                }

                self.stopTimer();
                self.timerIntervalId = window.setInterval(() => {
                    // TODO FIXME possibly closure over self
                    self.handleInterval();
                }, 1000);
            }
        };
    });

export type ISurveyQuestionModel = Instance<typeof SurveyQuestionModel>;

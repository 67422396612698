import { FetchStatus } from '@webapp/common/lib/api';
import { LocalStorage } from '@webapp/common/lib/storage';
import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import type { IResidenceModel } from '@webapp/common/resources/mst-survey/residence';

export const mergeFetchStatus = (...statuses: Array<string>): FetchStatus => {
    if (statuses.some((status) => status === FetchStatus.ERROR)) {
        return FetchStatus.ERROR;
    }

    if (statuses.some((status) => status === FetchStatus.PENDING)) {
        return FetchStatus.PENDING;
    }

    if (statuses.every((status) => status === FetchStatus.DONE)) {
        return FetchStatus.DONE;
    }

    return null;
};

export type SavedAnswer = Pick<ISurveyQuestionModel, 'id' | 'answers' | 'groups' | 'response'>;

export const loadSavedAnswers = (
    domain: string,
    questions: Array<ISurveyQuestionModel>
): Array<ISurveyQuestionModel> => {
    let savedAnswers: Array<SavedAnswer> = [];

    try {
        const parsedSavedQuestions = LocalStorage.get<Array<SavedAnswer>>(`answers__${domain}`);
        if (Array.isArray(parsedSavedQuestions)) {
            savedAnswers = parsedSavedQuestions;
        }
    } catch (e) {}

    return questions.map((q) => {
        const savedAnswer = savedAnswers.find(({ id }) => q.id === id);
        if (savedAnswer) {
            return { ...q, ...savedAnswer };
        }
        return q;
    });
};

export const prepareResidences = (rows: Array<any>, parent: number): Array<IResidenceModel> =>
    rows.map(({ ID: id, LEVEL: level, NAME: name }) => ({
        id,
        name,
        level: parseInt(level, 10),
        parent
    }));

import { useCallback } from 'react';

import { deleteAllCookies } from '@webapp/common/lib/cookies';
import { LocalStorage } from '@webapp/common/lib/storage';
import { CrossInCircle, Remove } from '@webapp/ui/lib/icons';

import css from './clear-btn.css';

export const ClearBtn: FC = () => {
    const clearStorage = useCallback(() => {
        LocalStorage.clear();
        setTimeout(() => window.location.reload(), 100);
    }, []);

    const clearCookies = useCallback(() => {
        deleteAllCookies();
        setTimeout(() => window.location.reload(), 100);
    }, []);

    return (
        <div className={css.tools}>
            <div className={css.btn} title='clear local storage' onClick={clearStorage}>
                <CrossInCircle />
            </div>
            <div className={css.btn} title='clear cookies' onClick={clearCookies}>
                <Remove />
            </div>
        </div>
    );
};

import dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';
import uniqBy from 'lodash/unionBy';
import { flow } from 'mobx-state-tree';
import type { Recorder } from 'vmsg';

import { API_URL, MAX_PERSONAL_PASSWORD_ATTEMPTS } from '@webapp/common/conf';
import { ApiRespCode, CheckPasswordStatus, FetchStatus } from '@webapp/common/lib/api';
import { isBrowser } from '@webapp/common/lib/const';
import {
    clearSurveyCompleteCookie,
    clearSurveyTokenCookie,
    getSurveyCompleteCookie,
    setSurveyTokenCookie
} from '@webapp/common/lib/cookies';
import { LocalStorage } from '@webapp/common/lib/storage';
import { isEmbedded } from '@webapp/common/lib/ua';
import { createSubdomainUrl } from '@webapp/common/lib/utils';
import type { ISurveyInfo } from '@webapp/common/resources/mst-survey/info';
import { getLkToken, getSurveyParams, isOfflineMode } from '@webapp/common/resources/mst-survey/survey/lib';
import {
    CompletePageType,
    CurrentSection,
    filterWithImages,
    filterWithScore,
    getApiClass,
    SurveyStatus,
    TestCheckStatus,
    WelcomePageType
} from '@webapp/common/resources/survey';

import { saveAnswersOffline, sendSavedDetails, surveyApi } from './api';
import { SurveyBundleFinishType, SurveyBundleType } from './bundle';
import { calcCurrentBundleIndex, packBundles, splitByQuestionsWithTimer, splitByTimerOn } from './helpers/bundles';
import { loadSavedAnswers } from './helpers/common';
import { mapInfo } from './helpers/normalize';
import { fillQuestions, mapQuestionForSnapshot } from './helpers/question';
import type { ITestScore } from './survey';

const unexpectedError = (resp: AnyObject): boolean => !Object.values(ApiRespCode).includes(resp?.code as ApiRespCode);

const WEBSITE_PROTO = 'https://';

const loadedResidences = Object.create(null);
const loadedNested = Object.create(null);

export const actions = (self: any): any => ({
    setInfo(info: ISurveyInfo) {
        self.info = info;
    },
    setToken(token: string) {
        let tokenData = null;
        if (token) {
            surveyApi.setToken(token);

            if (isBrowser) {
                setSurveyTokenCookie(token);
            }

            try {
                tokenData = jwt_decode<{ data: AnyObject }>(token).data;
            } catch (e) {
                console.error(e);
            }
        } else {
            surveyApi.setToken(null);

            if (isBrowser) {
                clearSurveyTokenCookie();
            }
        }
        self.tokenData = tokenData;
        self.token = token;
    },
    setAgreementText(agreementText: string) {
        self.agreementText = agreementText;
    },
    setDomain(domain: string) {
        self.domain = domain;
    },
    checkPassword: flow(function* checkSurveyPasswordAction(password: string) {
        try {
            self.checkPasswordStatus = CheckPasswordStatus.PENDING;

            const { individualPasswords } = self.info.params.main;
            const token = individualPasswords
                ? yield surveyApi.checkIndividualPassword(password)
                : yield surveyApi.checkPassword(password);

            self.setToken(token);
            setSurveyTokenCookie(token);

            self.checkPasswordStatus = CheckPasswordStatus.DONE;
            yield self.init();
            return true;
        } catch (err) {
            console.error('checkSurveyPasswordAction:', err);
            if (err.code === ApiRespCode.ERR_REFILL_CODE) {
                self.checkPasswordStatus = CheckPasswordStatus.PASSWORD_ALREADY_USED;
            } else {
                self.incIndividualTries();
                if (self.individualTries >= MAX_PERSONAL_PASSWORD_ATTEMPTS) {
                    self.currentSection = CurrentSection.BLOCKED;
                }
                self.checkPasswordStatus = CheckPasswordStatus.ERROR;
            }
        }
        return false;
    }),
    setIndividualTries(tries: number): void {
        self.individualTries = tries;
    },
    setTestScore(testScore: ITestScore): void {
        self.testScore = testScore;
        const testQuestions = filterWithScore(self.questions);

        testQuestions.forEach(
            ({ params: { hours, minutes, seconds, timer }, setFactTime, setTargetTime, timerAgo }) => {
                if (timer) {
                    setFactTime(timerAgo);
                    const targetTime = dayjs.duration(hours * 3600 + minutes * 60 + seconds, 'seconds');
                    setTargetTime(targetTime);
                }
            }
        );

        const questionsById = testQuestions.reduce((acc, curr) => {
            acc[curr.id] = curr;
            return acc;
        }, {});

        Object.entries(testScore.questions).forEach(([id, { answered, answers, maxScore, score }]) => {
            const q = questionsById[id];

            q.setIsTestCheckedQuestion(answered);
            q.setCurrentPoints(score);
            q.setMaxPoints(maxScore);
            const questionAnswers = q.answers.reduce((acc, a) => {
                acc[a.id] = a;
                return acc;
            }, {});
            // @ts-ignore
            Object.entries(answers).forEach(([id, { answered, correct }]) => {
                const a = questionAnswers[id];

                if (correct) {
                    a.setTestCheckStatus(TestCheckStatus.VALID);
                }

                if (answered && !correct) {
                    a.setTestCheckStatus(TestCheckStatus.INVALID);
                }
            });
        });
    },
    incIndividualTries() {
        const tries = 1 + LocalStorage.get<number>(`${self.domain}-invalid-password`) || 0;
        LocalStorage.set(`${self.domain}-invalid-password`, tries);
        self.individualTries = tries;
    },
    doStopOrLoadComplete: flow(function* doStopOrLoadComplete(loadOnly = false) {
        let promo_code,
            questions = self.questions,
            testScore,
            quotaDisqual,
            pages = self.pages;

        if (getSurveyCompleteCookie() || loadOnly) {
            ({ pages, promo_code, questions, testScore } = LocalStorage.get(`survey_complete_${self.info.id}`));
        } else {
            if (self.recorder) {
                // TODO check
                yield self.stopAudioRecord();
            }
            ({ promo_code, testScore, quotaDisqual } = yield surveyApi.stopSurvey());
            if (quotaDisqual) {
                self.currentSection = CurrentSection.DISABLED;
                throw new Error('quota exceeded');
            } else {
                LocalStorage.set(`survey_complete_${self.info.id}`, {
                    promo_code,
                    questions,
                    testScore,
                    pages
                });
                parent?.postMessage('finish', '*');
            }
        }

        // TODO clean up core#330
        if (testScore && testScore.questions) {
            self.setTestScore(testScore);
        }

        self.questions = questions;
        self.pages = pages;
        self.promocode = promo_code;
    }),
    checkVkAuth: flow(function* checkVkAuth(authdata: string) {
        try {
            self.checkPasswordStatus = CheckPasswordStatus.PENDING;
            const token = yield surveyApi.checkVkAuth(
                self.domain,
                authdata,
                self.token,
                window.location.search.slice(1)
            );

            self.setToken(token);
            self.checkPasswordStatus = CheckPasswordStatus.DONE;
            self.infoFetchingStatus = FetchStatus.DONE;

            yield self.init();
        } catch (err) {
            console.error('checkVkAuth:', err);
            self.infoFetchingStatus = FetchStatus.ERROR;
        }
    }),
    fetchWithoutToken: flow(function* () {
        let data;

        try {
            const lktoken = getLkToken();
            const params = getSurveyParams();
            const search = window.location.search.substring(1);
            data = yield surveyApi.startSurvey(self.domain, self.token, params, lktoken, search);
            self.startDatetime = dayjs();
        } catch (e) {
            data = e?.response?.data;

            if (unexpectedError(data)) {
                throw e;
            }
        }

        switch (data.code) {
            case ApiRespCode.ERR_SVR_CODE:
            case ApiRespCode.ERR_IP_UNIQ_CODE:
                self.setInfo(mapInfo(data));
                return data;
            case ApiRespCode.ERR_ANSWERED_RESPONSE:
            case ApiRespCode.ERR_API_KEY:
                self.currentSection = CurrentSection.FINISH;
                self.info.params.main.completePageText = `<h2 style="text-align: center">${data.message}</h2>`;
                return;
            case ApiRespCode.SURVEY_FILLED:
                self.started = true;
                self.info.title = '';
                self.info.params.main.completePage = CompletePageType.SUCCESS_TEXT;
                self.currentSection = CurrentSection.FINISH;
                self.setInfo(mapInfo(data));
                return data;
            case ApiRespCode.ERR_404:
            case ApiRespCode.ERR_AUTH_CODE:
                return data;
            default:
        }

        if (data.code !== ApiRespCode.OK_CODE && data.code !== ApiRespCode.SURVEY_COMPLETE) {
            self.setToken(null);
            return data;
        }

        self.setToken(data.token);
        self.setAgreementText(data.agreementText);

        self.setInfo(mapInfo(data));
    }),
    fetchInfo: flow(function* fetchSurveyInfoAction() {
        let err = null;
        self.infoFetchingStatus = FetchStatus.PENDING;

        try {
            err = yield self.fetchWithoutToken();
        } catch (e) {
            if (unexpectedError(e?.response?.data)) {
                console.error('fetchSurveyInfoAction:', e);
            }
        }

        // TODO refactor
        if (err) {
            if (err.code === ApiRespCode.ERR_404) {
                self.infoFetchingStatus = FetchStatus.NOT_FOUND;
                return err;
            } else if (unexpectedError(err)) {
                self.infoFetchingStatus = FetchStatus.ERROR;
            } else {
                self.infoFetchingStatus = FetchStatus.DONE;
            }
        } else {
            self.infoFetchingStatus = FetchStatus.DONE;
        }

        return err;
    }),
    saveQuestionsSnapshot() {
        const questionsSnap = JSON.stringify(self.questions.map(mapQuestionForSnapshot));
        LocalStorage.set(`answers__${self.domain}`, questionsSnap);
    },
    prepareBundles(calcIndex = false) {
        const { timer, timerOn } = self.info.params.main;

        self.bundles = packBundles(self.pages, self.questions);

        if (timerOn && timer) {
            self.bundles = splitByTimerOn(timerOn, self.bundles);
        }

        self.bundles = splitByQuestionsWithTimer(self.bundles);

        if (calcIndex) {
            self.currentBundleIndex = calcCurrentBundleIndex(self.bundles);
        }

        if (self.withRandom) {
            self.storeBundles();
        }
    },
    storeBundles() {
        LocalStorage.set(`bundles_random_${self.domain}`, self.bundles);
    },
    loadBundles() {
        try {
            return LocalStorage.get(`bundles_random_${self.domain}`);
        } catch (e) {}
    },
    preloadImages: flow(function* preloadImages() {
        const withImages = filterWithImages(self.questions);

        if (withImages.length === 0 && !isOfflineMode()) return;

        try {
            yield caches.open('images').then(function (cache) {
                const url = withImages.reduce(
                    (u, { answers }) =>
                        u.concat(
                            answers.reduce((acc, a) => {
                                const path = a?.file?.path || '';
                                const imgUrl = `${API_URL}${path}`;
                                if (imgUrl !== API_URL) {
                                    acc.push(imgUrl);
                                }

                                return acc;
                            }, [])
                        ),
                    []
                );
                return cache.addAll(url);
            });
        } catch (e) {
            console.error(e);
        }
    }),
    fetchQuotas: flow(function* fetchSurveyQuotas() {
        const isAnyQuotaLogic = self.questions.some((q) =>
            q.logics.some((logic) => logic.type === 4 || logic.type === 5)
        );
        const isMoreThan5SecondFromLastCheck =
            self.lastQuotasGetDate === null || Date.now() - +self.lastQuotasGetDate > 5_000;

        if (isAnyQuotaLogic && isMoreThan5SecondFromLastCheck) {
            self.quotaStatusByQuestionId = yield surveyApi.getQuotas(self.info.id);
            self.lastQuotasGetDate = new Date();
        }
    }),
    fetchContent: flow(function* fetchSurveyContentAction() {
        try {
            self.contentFetchingStatus = FetchStatus.PENDING;
            const { pages, questions } = yield surveyApi.getSurveyData();

            self.pages = pages;
            self.questions = loadSavedAnswers(self.domain, questions);
            self.setFilledQuestions(self.questions);

            yield self.fetchQuotas();
            if (isOfflineMode()) {
                yield self.preloadImages();
            }

            const loaded = self.loadBundles(); // TODO ?? load only withRandom
            if (loaded) {
                self.bundles = loaded;
            } else {
                self.prepareBundles(true);
            }

            self.contentFetchingStatus = FetchStatus.DONE;
        } catch (err) {
            console.error('fetchContent:', err);

            self.contentFetchingStatus = FetchStatus.ERROR;
        }
    }),
    showDisabled(): void {
        self.currentSection = CurrentSection.DISABLED;
    },
    startAudioRecord() {
        try {
            self.recorder.startRecording();
            self.audioStarted = true;
        } catch (e) {
            console.error(e);
            // alert('Запись аудио недоступна на этом устройстве');
        }
    },
    initAudioRecord: flow(function* initAudioRecord(recorder: Recorder) {
        try {
            yield recorder.init();
            self.recorder = recorder;
            self.audioReady = true;
        } catch (e) {
            console.error(e);
            // alert('Запись аудио недоступна на данном устройстве');
        }
    }),
    stopAudioRecord: flow(function* stopAudioRecord() {
        try {
            const data = yield self.recorder.stopRecording();
            // self.audioStarted = false;
            yield self.sendAudioRecord(data);
        } catch (e) {
            console.error(e);
        }
    }),
    sendAudioRecord: flow(function* sendAudioRecord(data: Blob) {
        const { id } = self;
        try {
            yield surveyApi.sendAudio(id, data);
            self.audioSent = true;
        } catch (e) {
            console.error(e);
        }
    }),
    disqual: flow(function* disqualSurveyAction() {
        try {
            self.disqualStatus = FetchStatus.PENDING;
            yield surveyApi.disqualSurvey();
            self.status = SurveyStatus.STATUS_DISQUAL;
            clearSurveyTokenCookie();
            self.disqualStatus = FetchStatus.DONE;
            parent?.postMessage('disqual', '*');
        } catch (err) {
            console.error('disqualSurveyAction:', err);
            self.disqualStatus = FetchStatus.ERROR;
        }
    }),
    stop: flow(function* stopSurveyAction() {
        try {
            self.stopStatus = FetchStatus.PENDING;

            yield self.doStopOrLoadComplete();

            if (self.redirecting) {
                self.stopStatus = FetchStatus.DONE;
                return;
            }

            self.stopStatus = FetchStatus.DONE;
        } catch (err) {
            if (err?.code === ApiRespCode.ERR_END_SURVEY) {
                return self.showDisabled();
            }
            console.error('stop', JSON.stringify(err));
            console.trace('stop', err);
            self.stopStatus = FetchStatus.ERROR;
            throw err;
        }
    }),
    setTimeLeft(timeLeft) {
        self.timeLeft = dayjs.duration(timeLeft);
    },
    startTimer() {
        if (self.timeLeft) return;

        const timerStartValue = LocalStorage.get<string>(`timer-${self.domain}`);
        if (timerStartValue) {
            self.timerEnds = dayjs(timerStartValue);
        } else {
            const { timerHours, timerMinutes, timerSeconds } = self.info.params.main;
            self.timerEnds = dayjs().add(timerHours, 'h').add(timerMinutes, 'm').add(timerSeconds, 's');
        }
        LocalStorage.set(`timer-${self.domain}`, self.timerEnds.toString());

        const interval = setInterval(() => {
            const now = dayjs();

            if (self.finishStatus) {
                // already stopping
                clearInterval(interval);
                return;
            }

            if (self.timerEnds.isAfter(now)) {
                const diff = self.timerEnds.diff(now);
                self.setTimeLeft(diff);
            } else {
                clearInterval(interval);
                self.finish(SurveyBundleFinishType.STOP_BY_TIMER);
            }
        }, 500);
    },
    init: flow(function* initSurveyAction() {
        try {
            const fetchError = yield self.fetchInfo();

            if (self.infoFetchingStatus !== FetchStatus.DONE) {
                return;
            }

            if (self.individualTries >= MAX_PERSONAL_PASSWORD_ATTEMPTS) {
                self.currentSection = CurrentSection.BLOCKED;
                return;
            }

            const { disallowRefilling } = self.info.params.other;

            if (getSurveyCompleteCookie() && disallowRefilling) {
                self.showFinishPage();
                // TODO use state machine, not flags
                self.started = true;
                return;
            }

            if (fetchError) {
                switch (fetchError.code) {
                    case ApiRespCode.ERR_IP_UNIQ_CODE:
                        self.showFinishPage();
                        return;
                    case ApiRespCode.ERR_SVR_CODE:
                        return self.showDisabled();
                    case ApiRespCode.ERR_AUTH_CODE:
                        self.currentSection = CurrentSection.AUTH;
                        return;
                    default:
                }
            }

            const { individualPasswords, passwordAccess, vkAuth } = self.info.params.main;
            const authOn = individualPasswords || passwordAccess || vkAuth;

            if (authOn && !self.authenticated) {
                self.currentSection = CurrentSection.AUTH;
                return;
            }

            if (self.welcomePage === WelcomePageType.TO_SINGLE_PAGE && !self.started) {
                self.currentSection = CurrentSection.START;
            }
        } catch (err) {
            console.error('initSurveyAction:', err);
        }
    }),
    start: flow(function* startSurveyAction(upd?: boolean) {
        try {
            const { timer, timerOn } = self.info.params.main;

            if (self.started && !upd) {
                return false;
            }

            self.setToken(self.token);
            yield self.fetchContent();

            if (self.contentFetchingStatus !== FetchStatus.DONE) {
                return false;
            }

            if (timer && !timerOn) {
                self.startTimer();
            }

            if (!upd && !self.started) {
                self.currentSection = CurrentSection.BUNDLES;
            }

            self.started = true;
            return true;
        } catch (err) {
            console.error('start', err);
        }
    }),
    finish: flow(function* finishSurveyAction(type: SurveyBundleFinishType) {
        try {
            self.finishStatus = FetchStatus.PENDING;
            self.stopDatetime = dayjs();

            if (type !== SurveyBundleFinishType.STOP_BY_TIMER) {
                const bundleQuestionsIds = self.bundles
                    .filter(({ type }) => type === SurveyBundleType.QUESTIONS)
                    .flatMap(({ questions }) => questions);
                const shownQuestions = self.questions.filter((q) => bundleQuestionsIds.includes(q.id));

                shownQuestions.map((question) => question.validate());

                if (shownQuestions.some(({ invalid }) => invalid)) {
                    self.finishStatus = FetchStatus.DONE;
                    self.selectNextBundleStatus = undefined;
                    return false;
                }
            }

            const offline = isOfflineMode();

            if (self.saveAtFinish || offline) {
                const answers = self.questions
                    .filter((q) => q.wasChanged)
                    .map((question) => getApiClass(question.type).query(question))
                    .filter(Boolean);

                if (answers.length > 0) {
                    if (offline) {
                        yield saveAnswersOffline(answers, self);
                    } else {
                        yield surveyApi.sendAllAnswers(answers);
                    }
                }
            } else {
                try {
                    yield sendSavedDetails();
                } catch (err) {
                    if (err?.code === ApiRespCode.ERR_DELETED_RESPONSE) {
                        self.setToken(null);
                        yield self.fetchWithoutToken();
                        yield sendSavedDetails();
                    } else {
                        throw err;
                    }
                }
            }

            switch (type) {
                case SurveyBundleFinishType.STOP:
                case SurveyBundleFinishType.STOP_BY_TIMER:
                case SurveyBundleFinishType.TEXT:
                    try {
                        yield self.stop();
                    } catch (e) {
                        self.finishStatus = FetchStatus.ERROR;
                        throw e;
                    }
                    const { disallowRefilling } = self.info.params.other;
                    if (disallowRefilling) {
                        LocalStorage.remove(`answers__${self.domain}`);
                        LocalStorage.remove(`timer-${self.domain}`);
                        LocalStorage.remove('question_details');
                    } else {
                        clearSurveyCompleteCookie();
                        clearSurveyTokenCookie();
                        LocalStorage.clear();
                    }
                    if (self.stopStatus !== FetchStatus.DONE) {
                        return false;
                    }
                    break;
                case SurveyBundleFinishType.DISQUAL:
                    yield self.disqual();

                    if (self.disqualStatus !== FetchStatus.DONE) {
                        return false;
                    }
                    break;
            }

            if (
                type === SurveyBundleFinishType.STOP ||
                (type === SurveyBundleFinishType.STOP_BY_TIMER && self.currentSection !== CurrentSection.TEST_RESULT)
            ) {
                if (!self.redirecting) {
                    self.showFinishPage();
                }
            }

            self.finishStatus = FetchStatus.DONE;
        } catch (err) {
            const code = err?.code;

            if (code === ApiRespCode.ERR_END_SURVEY) {
                self.finishStatus = FetchStatus.DONE;
                return self.showDisabled();
            }

            console.error('finish', JSON.stringify(err), code);
            self.finishStatus = FetchStatus.ERROR;
        }
    }),
    showFinishPage() {
        const {
            main: { completePage, completeSurvey, completeSurveyUrl, completeWebsite },
            other: { copyUrlParams }
        } = self.info.params;

        if (self.testScore !== null && completePage === CompletePageType.TEST_RESULTS) {
            // TODO merge with switch
            self.currentSection = CurrentSection.TEST_RESULT;
            return;
        }

        const { nextBundle } = self;

        if (nextBundle) {
            const { finishType, type } = nextBundle;

            if (finishType === SurveyBundleFinishType.STOP && type === SurveyBundleType.TEXT) {
                self.currentSection = CurrentSection.BUNDLES;
                self.selectNextBundleStatus = FetchStatus.DONE;
                return;
            }
        }

        try {
            switch (completePage) {
                case CompletePageType.SUCCESS_TEXT:
                    self.currentSection = CurrentSection.FINISH;
                    break;
                case CompletePageType.REDIRECT_TO_WEBSITE:
                    let url = completeWebsite + (copyUrlParams ? window.location.search : '');

                    if (!url.startsWith(WEBSITE_PROTO)) {
                        url = WEBSITE_PROTO + url;
                    }

                    if (url === WEBSITE_PROTO) throw new Error('complete website not specified');

                    setTimeout(() => {
                        if (isEmbedded) {
                            window.open(url);
                        } else {
                            window.location.href = url;
                        }
                    }, 100);
                    return;
                case CompletePageType.TO_SURVEY:
                    if (!completeSurvey || !completeSurveyUrl) throw new Error('complete survey not specified');
                    window.location.href =
                        createSubdomainUrl(completeSurveyUrl) + (copyUrlParams ? window.location.search : '');
                    return;
                case CompletePageType.SURVEY_RESULTS:
                    self.currentSection = CurrentSection.RESULTS;
                    break;
                case CompletePageType.TEST_RESULTS:
                    if (getSurveyCompleteCookie()) {
                        void self.doStopOrLoadComplete(true);
                    }

                    self.currentSection = CurrentSection.TEST_RESULT;
                    break;
            }
        } catch (e) {
            console.error(e);
            self.currentSection = CurrentSection.FINISH;
            self.params.main.completePage = CompletePageType.SUCCESS_TEXT;
        }
    },
    selectPrevBundle() {
        self.currentBundleIndex--;
        setTimeout(() => window.scrollTo(0, 0), 100);
    },
    setScrollToQuestionId(questionId: number): void {
        self.scrollToQuestionId = questionId;
    },
    selectNextBundle: flow(function* selectNextBundleAction(noValidate = false): any {
        try {
            self.selectNextBundleStatus = FetchStatus.PENDING;

            if (self.currentBundle) {
                if (noValidate) {
                    self.currentBundle.stopTimers();
                } else {
                    self.currentBundle.validate();

                    if (self.currentBundle.isValid) {
                        self.currentBundle.stopTimers();
                        self.setScrollToQuestionId(-1);
                    } else {
                        self.selectNextBundleStatus = undefined;
                        self.setScrollToQuestionId(self.firstInvalidId);
                        return false;
                    }
                }
            }

            const { nextBundle } = self;

            if (!nextBundle) {
                yield self.finish(SurveyBundleFinishType.STOP);
                self.selectNextBundleStatus = FetchStatus.DONE;
                return;
            }
            const { finishType } = nextBundle;

            if (finishType === SurveyBundleFinishType.DISQUAL || finishType === SurveyBundleFinishType.STOP) {
                yield self.finish(finishType);

                if (
                    (finishType === SurveyBundleFinishType.DISQUAL && self.disqualStatus === FetchStatus.ERROR) ||
                    (finishType === SurveyBundleFinishType.STOP && self.stopStatus !== FetchStatus.DONE)
                ) {
                    return false;
                }
            }

            switch (nextBundle.type) {
                case SurveyBundleType.TEXT:
                    self.currentBundleIndex++;
                    setTimeout(() => window.scrollTo(0, 0), 100);
                    self.selectNextBundleStatus = FetchStatus.DONE;
                    return;
                case SurveyBundleType.REDIRECT_SURVEY:
                case SurveyBundleType.REDIRECT_URL:
                    const {
                        other: { copyUrlParams }
                    } = self.info.params;

                    const url = nextBundle.redirect + (copyUrlParams ? window.location.search : '');
                    window.location.href = url;
                    return true;
            }

            const { timerOn } = self.info.params.main;
            if (timerOn) {
                const isStartTimer = self.currentBundle.questions.find(({ id }) => String(timerOn) === String(id));
                if (isStartTimer) {
                    self.startTimer();
                }
            }

            self.currentBundleIndex++;
            setTimeout(() => window.scrollTo(0, 0), 100);
            parent && parent.postMessage('scrollTop', '*');

            self.selectNextBundleStatus = FetchStatus.DONE;
        } catch (err) {
            console.error('selectNextBundleAction:', err);

            self.selectNextBundleStatus = FetchStatus.ERROR;
        }
    }),
    getResidences: flow(function* getResidences(level, parent) {
        const key = `${level}_${parent}`;

        if (loadedResidences[key]) return;

        let residences = [];

        try {
            residences = yield surveyApi.getResidences(level, parent);
        } catch (e) {
            return;
        }

        loadedResidences[key] = true;

        self.residence = uniqBy(self.residence ? self.residence.concat(residences) : [residences], (r: any) => r.id);
    }),
    getNested: flow(function* getNested(questionId: number, level: number, parentId: number | null) {
        const key = `${questionId}_${level}_${parentId}`;

        if (loadedNested[key]) return;

        let items = [];

        try {
            ({ items } = yield surveyApi.getNested(questionId, level, parentId));
        } catch (e) {
            return;
        }

        loadedNested[key] = true;

        const q = self.questions.find((q) => q.id === questionId);

        q.items.set(level, items);
    }),
    setFilledQuestions(questions) {
        fillQuestions(questions, self.info?.details);
    }
});

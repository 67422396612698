import { useRouter } from 'next/router';
import { useEffect, useMemo, useRef, useState } from 'react';

import { isBrowser } from '../lib/const';
import { nop } from '../lib/utils';

let deferredPrompt = null;

export const useWorkbox = (
    enabled = false
): {
    messageSW: (data: any) => void;
    active: boolean;
    online: boolean;
    install: () => void;
} => {
    const [online, setOnline] = useState(true);
    const router = useRouter();
    const withSw = isBrowser && 'serviceWorker' in navigator && window.workbox !== undefined;
    const withOnline = isBrowser && 'ononline' in window && 'onoffline' in window;
    const wb = isBrowser && window.workbox;
    const [active, setActive] = useState(false);
    const messageSW = useRef(wb ? wb.messageSW.bind(wb) : nop);
    const [install, setInstall] = useState(null);

    wb &&
        wb.active.then(() => {
            setActive(true);
            messageSW.current = wb.messageSW.bind(wb);
        });

    useEffect(() => {
        if (isBrowser && withOnline) {
            setOnline(window.navigator.onLine);
            window.addEventListener('online', () => {
                setOnline(true);
            });
            window.addEventListener('offline', () => {
                setOnline(false);
            });
        }
    }, [withOnline]);

    useEffect(() => {
        if (isBrowser && online && enabled) {
            if (router.route !== '/') {
                void wb.active.then((_worker) => {
                    void wb.messageSW({ action: 'CACHE_NEW_ROUTE' });
                });
            }
        }
    }, [wb, online, router.route, enabled]);

    useEffect(() => {
        if (isBrowser && enabled && withSw) {
            // TODO check { updateViaCache: 'imports' }
            void wb.register();
        }
    }, [enabled, wb, withSw]);

    useEffect(() => {
        if (isBrowser && enabled) {
            window.addEventListener('beforeinstallprompt', (event) => {
                event.preventDefault();
                deferredPrompt = event;

                setInstall(() => () => {
                    deferredPrompt.prompt();

                    deferredPrompt.userChoice;
                });
            });

            window.addEventListener('appinstalled', () => {
                window.location.reload();
            });
        }
    }, [enabled]);

    useEffect(() => {
        if (enabled && isBrowser) {
            if (!('ononline' in window) || !('onoffline' in window || !('serviceWorker' in navigator))) {
                alert('Для работы оффлайн-режима необходим современный браузер');
            }
        }
    }, [enabled]);

    useEffect(() => {
        if (isBrowser && !withSw) {
            alert('В этом браузере offline-режим недоступен');
        }
    }, [withSw]);

    return useMemo(
        () =>
            isBrowser
                ? {
                      active,
                      online,
                      messageSW: messageSW.current,
                      install
                  }
                : {
                      active: false,
                      online: true,
                      messageSW: nop,
                      install
                  },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [active, online, messageSW.current]
    );
};

import { ColorScheme, getColor } from '@webapp/common/lib/ui';

export interface Fonts {
    fontFamily: string;
    customFontFamily: string;

    titleSize: number;
    titleColor: string;
    titleBold: boolean;
    titleItalic: boolean;
    titleUnderline: boolean;
    titleThrough: boolean;

    questionTextSize: number;
    questionTextColor: string;
    questionTextBold: boolean;
    questionTextItalic: boolean;
    questionTextUnderline: boolean;
    questionTextThrough: boolean;

    replyTextSize: number;
    replyTextColor: string;
    replyTextBold: boolean;
    replyTextItalic: boolean;
    replyTextUnderline: boolean;
    replyTextThrough: boolean;

    elementColor: string;
    fieldColor: string;
    fontColor: string;
    buttonColor: string;
    buttonTextColor: ColorScheme;
    fieldBorderColor: string;
}

export const initialFonts: Readonly<Fonts> = {
    fontFamily: 'Montserrat',
    customFontFamily: '',

    titleSize: 36,
    titleColor: getColor('grey3'),
    titleBold: true,
    titleItalic: false,
    titleUnderline: false,
    titleThrough: false,

    questionTextSize: 22,
    questionTextColor: getColor('grey3'),
    questionTextBold: true,
    questionTextItalic: false,
    questionTextUnderline: false,
    questionTextThrough: false,

    replyTextSize: 16,
    replyTextColor: getColor('grey3'),
    replyTextBold: false,
    replyTextItalic: false,
    replyTextUnderline: false,
    replyTextThrough: false,

    elementColor: getColor('blue'),
    fieldColor: getColor('blue'),
    fontColor: getColor('black'),
    buttonColor: getColor('blue'),
    buttonTextColor: ColorScheme.LIGHT,
    fieldBorderColor: getColor('blue')
};

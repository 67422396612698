import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { useEffect, useMemo } from 'react';

import '@webapp/common/lib/date';
import '@webapp/common/assets/styles/globals.css?CSSModulesDisable';

import { ClearBtn } from '@webapp/common/components/clear-btn';
import { useDevMode } from '@webapp/common/hooks/use-dev-mode';
import { isBrowser, isServer } from '@webapp/common/lib/const';
import { isOfflineMode } from '@webapp/common/resources/mst-survey/survey/lib';
import { DevIndicator } from '@webapp/ui/lib/dev-indicator';

import { SurveyHead } from 'components/head';
import { OfflineTools } from 'components/view/offline';

import { initializeStore, StoreProvider } from '../store';
import type { StoreInstance } from '../store';

const SurveyApp: NextPage<
    AppProps & {
        initialState: any;
    }
> = ({ Component, initialState, pageProps }) => {
    const ttDev = useDevMode();
    const store: StoreInstance = useMemo(() => initializeStore(initialState), [initialState]);
    const withOffline = isBrowser && isOfflineMode();

    useEffect(() => {
        if (!isServer) {
            store.devTools.setEnabled(ttDev);
        }
    }, [store.devTools, ttDev]);

    if (isServer) return null; // TODO FIXME #493 #708

    return (
        <StoreProvider value={store}>
            <SurveyHead />
            {ttDev && <ClearBtn />}
            {ttDev && <DevIndicator data={[store?.survey?.info?.id]} />}
            <Component {...pageProps} />
            {withOffline && <OfflineTools />}
        </StoreProvider>
    );
};

// eslint-disable-next-line import/no-default-export
export { SurveyApp as default };

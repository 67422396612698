import type { Instance } from 'mobx-state-tree';
import { types } from 'mobx-state-tree';

const ParamModel = types.model('survey_question_survey_logic', {
    id: types.union(types.number, types.string),
    checked: types.maybeNull(types.boolean)
});

export const SurveyQuestionLogicModel = types.model('survey_question_survey', {
    id: types.identifierNumber,
    type: types.maybeNull(types.number),
    action: types.maybeNull(types.string),
    transition: types.maybeNull(types.number),
    transitionType: types.maybeNull(types.number),
    boolFunc: types.maybeNull(types.union(types.literal('OR'), types.literal('AND'))),
    toQuestions: types.optional(types.array(types.number), []),
    toPages: types.optional(types.array(types.number), []),
    toQuestion: types.maybeNull(types.number),
    toSurvey: types.maybeNull(types.number),
    toSurveyUrl: types.maybeNull(types.string),
    toPage: types.maybeNull(types.number),
    toWebsite: types.maybeNull(types.string),
    completeText: types.maybeNull(types.string),
    disqualText: types.maybeNull(types.string),
    params: types.optional(types.array(ParamModel), [])
});

export type ISurveyQuestionLogicModel = Instance<typeof SurveyQuestionLogicModel>;

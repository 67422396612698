import type { Instance } from 'mobx-state-tree';
import { types } from 'mobx-state-tree';

import { CheckPasswordStatus, FetchStatus } from '@webapp/common/lib/api';
import { isBrowser } from '@webapp/common/lib/const';
import { SurveyInfoModel } from '@webapp/common/resources/mst-survey/info';
import { SurveyPageModel } from '@webapp/common/resources/mst-survey/page';
import { SurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import { ResidenceModel } from '@webapp/common/resources/mst-survey/residence';
import { DateTimeType } from '@webapp/common/resources/mst-types/datetime';
import { DurationModel } from '@webapp/common/resources/mst-types/duration';
import { Enumeration, NEnumeration } from '@webapp/common/resources/mst-types/nenumerations';
import { CurrentSection, SurveyStatus } from '@webapp/common/resources/survey';

import { actions } from './actions';
import { surveyApi } from './api';
import { SurveyBundleModel } from './bundle';
import { views } from './views';

const TokenAuthSettings = types.model('TokenAuthSettings', {
    password: types.maybeNull(types.number),
    vkAuth: types.maybeNull(types.number)
});

const TokenData = types.model('TokenData', {
    private: types.maybeNull(types.union(types.number, TokenAuthSettings)),
    license: types.maybeNull(types.number),
    rid: types.maybeNull(types.union(types.string, types.number)),
    survey: types.maybeNull(types.number)
});

export const TestScore = types.model('test_score', {
    maxScore: types.optional(types.number, 0),
    score: types.optional(types.number, 0),
    percent: types.optional(types.number, 0),
    questions: types.frozen({})
});

export const QuotaStatusAndQuestionId = types.model('quota_status_and_question_id', {
    quotaStatus: types.optional(types.boolean, true),
    questionId: types.optional(types.number, 0)
});

export const SurveyStore = types
    .model('survey_store', {
        // data
        agreementText: types.maybeNull(types.string),
        domain: types.maybeNull(types.string),
        token: types.maybeNull(types.string),
        tokenData: types.maybeNull(TokenData),
        promocode: types.optional(types.string, ''),
        testScore: types.maybeNull(TestScore),
        info: types.optional(SurveyInfoModel, {}),
        pages: types.array(SurveyPageModel),
        residence: types.optional(types.array(ResidenceModel), []),
        questions: types.array(SurveyQuestionModel),
        bundles: types.array(SurveyBundleModel),
        quotaStatusByQuestionId: types.array(QuotaStatusAndQuestionId),
        lastQuotasGetDate: types.maybeNull(types.Date),

        // statuses
        infoFetchingStatus: types.optional(Enumeration(FetchStatus), FetchStatus.PENDING),
        contentFetchingStatus: types.maybe(Enumeration(FetchStatus)),
        stopStatus: types.maybe(Enumeration(FetchStatus)),
        disqualStatus: types.maybe(Enumeration(FetchStatus)),
        finishStatus: types.maybe(Enumeration(FetchStatus)),
        selectNextBundleStatus: types.maybe(Enumeration(FetchStatus)),
        checkPasswordStatus: types.maybe(Enumeration(CheckPasswordStatus)),

        // audio
        audioReady: types.optional(types.boolean, false),
        audioStarted: types.optional(types.boolean, false),
        audioSent: types.optional(types.boolean, false),

        // state
        startDatetime: types.maybeNull(DateTimeType),
        stopDatetime: types.maybeNull(DateTimeType),
        status: types.maybe(NEnumeration(SurveyStatus)),
        started: types.optional(types.boolean, false),
        timerEnds: types.maybeNull(DateTimeType),
        timeLeft: DurationModel, // TODO ?? use volatile
        currentBundleIndex: types.optional(types.number, 0),
        factTime: DurationModel,
        targetTime: DurationModel,
        currentSection: types.maybe(Enumeration(CurrentSection)),
        individualTries: types.maybeNull(types.number),

        scrollToQuestionId: types.maybeNull(types.number)
    })
    .volatile((_) => ({
        recorder: null
    }))
    .views(views)
    .actions(actions)
    .preProcessSnapshot((snapshot) => {
        if (isBrowser && snapshot && snapshot.token) {
            surveyApi.setToken(snapshot.token);
        }

        return snapshot;
    });

export type ISurveyStore = Instance<typeof SurveyStore>;
export type ITestScore = Instance<typeof TestScore>;

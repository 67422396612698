import { observer } from 'mobx-react-lite';
import Head from 'next/head';
import { useEffect } from 'react';

import { FetchStatus } from '@webapp/common/lib/api';
import { isBrowser } from '@webapp/common/lib/const';
import { gtagInit, gtagSrc, YaMetrikaCounter } from '@webapp/common/lib/metrics';
import { FONTS } from '@webapp/common/resources/survey';
import { Loader } from '@webapp/ui/lib/loader';

import { useStore } from '../store';

// TODO use next/script
const embedoInit = `(() => {
    const containers = new WeakMap();
    let resizeTimeout;
    const each = Array.prototype.forEach;

    function resize() {
        const nodes = document.querySelectorAll('oembed[url]');
        each.call(nodes, function (element) {
            const embedo = containers.get(element.parentNode);
            if (embedo) {
                embedo.refresh(element.parentNode);
            }
        });
    }

    function resizeThrottler() {
        if (!resizeTimeout) {
            resizeTimeout = setTimeout(function () {
                resizeTimeout = null;
                resize();
            }, 200);
        }
    }

    window.addEventListener('orientationchange', resize);
    window.addEventListener('resize', resizeThrottler, false);

    setInterval(function () {
        const nodes = document.querySelectorAll('oembed[url]');
        each.call(nodes, function (element) {
            let embedo = containers.get(element.parentNode);

            if (!embedo) {
                embedo = new Embedo();
                containers.set(element.parentNode, embedo);
                embedo
                    .load(element.parentNode, element.getAttribute('url'), {
                        width: '100%',
                        height: 'min-content',
                        strict: true
                    })
                    .fail(console.error);
            }
        });
    }, 500);
})();
`;

const styles = `
body {
    touch-action: pan-x pan-y; /* IOS prevent touch zoom */
}
@supports (-webkit-touch-callout: none) {
    input[type="text"]:focus,
    input[type="number"]:focus {
        font-size: 16px; /* Adding 16px on focus will prevent IOS page zoom */
    }
}
#pooper {
    z-index: 10000;
}
.hideScroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.hideScroll::-webkit-scrollbar {
    display: none;
}
figure {
    width: 100%;
    margin: 0;
}
figure img {
    width: 100%;
}
.image.image_resized img {
    width: 100%
}
.image.image-style-align-left {
    margin-right: 1.5rem;
    float: left;
}
.image.image-style-align-center {
    margin-left: auto !important;
    margin-right: auto !important;
}
.image.image-style-align-right {
    float: right;
    margin-left: 1.5rem;
}
.image.image-style-side {
    float: right;
    margin-left: 1.5rem;
    max-width: 50%;
}
figure table {
    width: 100%;
    height: 100%;
}
figure table figure {
    width: 100%;
    height: 100%;
}`;

/*
Registration for Launch Handler
Origin www.testograf.ru
Match subdomains Yes
Trial Available Up to Chrome 110 (ends with the rollout of next Chrome release), no later than Mar 1, 2023
Asa7YKsc1y58MInOI7iP1GJk3Vy3lf0WLctSVajKp9ZTEZw5Qu9FbTDYj/kLeZymsm0w1Pn1r6qKAkazVfiIdAkAAABreyJvcmlnaW4iOiJodHRwczovL3d3dy50ZXN0b2dyYWYucnU6NDQzIiwiZmVhdHVyZSI6IkxhdW5jaCBIYW5kbGVyIiwiZXhwaXJ5IjoxNjc3NjI4Nzk5LCJpc1N1YmRvbWFpbiI6dHJ1ZX0=
*/

export const SurveyHead = observer(() => {
    const { survey } = useStore();
    const pageTitle = survey?.info?.title || survey?.domain || '';
    const font = survey?.info?.design?.fonts?.fontFamily;
    const fontFamily = FONTS[font];
    const loading = survey.infoFetchingStatus === FetchStatus.PENDING;

    if (font && typeof fontFamily === 'undefined') {
        console.error(`url mapping missing for font: ${font}`);
    }

    useEffect(() => {
        if (isBrowser) {
            const html = document.getElementsByTagName('html')[0];
            html.style.fontFamily = font;
        }
    }, [font]);

    return (
        <>
            {<Loader visible={loading} />}
            <Head>
                <title>{pageTitle}</title>
                <meta content='Testograf Survey' name='application-name' />
                <meta
                    content='Asa7YKsc1y58MInOI7iP1GJk3Vy3lf0WLctSVajKp9ZTEZw5Qu9FbTDYj/kLeZymsm0w1Pn1r6qKAkazVfiIdAkAAABreyJvcmlnaW4iOiJodHRwczovL3d3dy50ZXN0b2dyYWYucnU6NDQzIiwiZmVhdHVyZSI6IkxhdW5jaCBIYW5kbGVyIiwiZXhwaXJ5IjoxNjc3NjI4Nzk5LCJpc1N1YmRvbWFpbiI6dHJ1ZX0='
                    httpEquiv='origin-trial'
                />
                <meta charSet='utf-8' />
                <meta
                    content='width=device-width, initial-scale=1.0, shrink-to-fit=no, minimum-scale=1.0, maximum-scale=1.0, user-scalable=yes'
                    name='viewport'
                />
                <meta content='yes' name='apple-mobile-web-app-capable' />
                <meta content='#318fff' name='msapplication-TileColor' />
                <meta content='true' name='HandheldFriendly' />
                <meta content='width' name='MobileOptimized' />
                <meta content='#f3f3f3' name='theme-color' />
                <meta content='noindex' name='robots' />
                <link href='https://fonts.googleapis.com' rel='preconnect' />
                <link crossOrigin='anonymous' href='https://fonts.gstatic.com' rel='preconnect' />
                {fontFamily && <link href={fontFamily} rel='stylesheet' />}
                <link href='/apple-touch-icon.png?v=5' rel='apple-touch-icon' sizes='180x180' />
                <link href='/favicon-32x32.png?v=5' rel='icon' sizes='32x32' type='image/png' />
                <link href='/favicon-16x16.png?v=5' rel='icon' sizes='16x16' type='image/png' />
                <link href={`/manifest.json?v=1&t=${pageTitle}`} rel='manifest' />
                <link color='#318fff' href='/safari-pinned-tab.svg?v=5' rel='mask-icon' />
                <link href='/favicon.ico?v=5' rel='shortcut icon' />

                <script src='/vendor/embedo.min.js' />
                <script dangerouslySetInnerHTML={{ __html: embedoInit }} />
                <script async src={gtagSrc} />
                <script dangerouslySetInnerHTML={{ __html: gtagInit }} />
                <script dangerouslySetInnerHTML={{ __html: YaMetrikaCounter }} />

                <style>{styles}</style>
            </Head>
        </>
    );
});

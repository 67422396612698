import { createStore, del, entries, get, keys, update } from 'idb-keyval';
import type { AnyObject } from 'immer/dist/internal';
import { nanoid } from 'nanoid';
import type { NestedItem } from 'resources/questions';
import { mapNested } from 'resources/questions/map';

import { API_URL } from '@webapp/common/conf';
import { ApiRespCode, getFingerprint } from '@webapp/common/lib/api';
import { isBrowser } from '@webapp/common/lib/const';
import { getUserToken } from '@webapp/common/lib/cookies';
import { getFormattedParams, hasPendingRequests, ttFetch } from '@webapp/common/lib/fetch';
import { LocalStorage } from '@webapp/common/lib/storage';
import { getUrlSearchParam } from '@webapp/common/lib/utils';
import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';

import type { AnswersRequest } from '../api/queries-api';
import type { IResidenceModel } from '../residence';

import { prepareResidences } from './helpers/common';
import { mapPages, mapQuestions } from './helpers/normalize';
import { isOfflineMode } from './lib';
import type { ISurveyStore } from './survey';

const fetch = ttFetch({
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        accept: 'application/json'
    },
    retries: 1
});

const fetchGet = ttFetch({
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        accept: 'application/json'
    },
    retries: 1
});

const fingerprint = getFingerprint();

class SurveyRequest {
    static token: string = null;

    static post = (
        path: string,
        body?: JsonValue | FormData,
        headers = {},
        params?: Record<string, any>
    ): Promise<any> => {
        const formattedParams = getFormattedParams(params);

        return fetch(`${API_URL}/survey/v1/${path}${formattedParams}`, {
            headers: {
                ...(SurveyRequest.token && { 'X-Authorization': SurveyRequest.token }),
                ...headers
            },
            // @ts-ignore
            body
        });
    };
    static get = (path: string, headers = {}): Promise<any> =>
        fetchGet(`${API_URL}/survey/v1/${path}`, {
            headers: {
                ...(SurveyRequest.token && { 'X-Authorization': SurveyRequest.token }),
                ...headers
            }
        });

    static corePost = (body?: JsonValue | FormData, headers = {}): Promise<any> =>
        fetch(`${API_URL}/core/v1/upload/user/file`, {
            headers: {
                ...(SurveyRequest.token && { 'X-Authorization': SurveyRequest.token }),
                ...headers
            },
            // @ts-ignore
            body
        });
}

const uploadFile = async (questionId: string | number, file: File): Promise<AnyObject> => {
    const body = new FormData();

    body.append('upfile[]', file, file.name);
    body.append('question', String(questionId));

    const { code, files, message } = await SurveyRequest.post('upload/response/file', body, {
        'Access-Control-Request-Headers': 'Content-Type,X-Authorization',
        'Access-Control-Request-Method': 'POST'
    });

    if (code !== ApiRespCode.OK_CODE) {
        const err = new Error(message);
        console.error(err);
        throw err;
    }

    const { id, path, realName } = files[0];

    return {
        id: parseInt(id),
        type: null,
        file: {
            id: parseInt(id),
            path,
            name: realName,
            size: file.size
        }
    };
};

const uploadUserFile = async (file: File, folder?: string): Promise<AnyObject> => {
    const body = new FormData();
    const userToken = getUserToken();

    body.append('upfile[]', file, file.name);
    body.append('folder', String(folder));
    body.append('token', userToken);

    const { code, files, message } = await SurveyRequest.corePost(body, {
        'Access-Control-Request-Headers': 'Content-Type,X-Authorization',
        'Access-Control-Request-Method': 'POST'
    });

    if (code !== ApiRespCode.OK_CODE) {
        const err = new Error(message);
        console.error(err);
        throw err;
    }

    return files?.[0];
};

export class SurveyAPI {
    checkIndividualPassword = async (password: string): Promise<string> => {
        const resp = await SurveyRequest.post('check/individual_password', {
            password
        });
        const { code, token } = resp;

        if (code !== ApiRespCode.OK_CODE) {
            throw resp;
        }

        return token;
    };

    checkPassword = async (password: string): Promise<string> => {
        const resp = await SurveyRequest.post('check/password', {
            password
        });
        const { code, token } = resp;

        if (code !== ApiRespCode.OK_CODE) {
            throw resp;
        }

        return token;
    };

    getQuotas = async (surveyId: number): Promise<Array<{ questionId: number; quotaStatus: boolean }>> => {
        const resp = await SurveyRequest.get(`check/responses_limit?surveyId=${surveyId}`);
        return resp.quotas.questions.map((question) => ({ questionId: question.ID, quotaStatus: question.check }));
    };

    checkVkAuth = async (rewrite: string, vkauth: string, token?: string, referer?: string): Promise<any> => {
        const resp = await SurveyRequest.post('check/vk_auth', {
            rewrite,
            token,
            vkauth,
            referer
        });
        const { code, token: respToken } = resp;

        if (code !== ApiRespCode.OK_CODE) {
            throw resp;
        }

        return respToken;
    };

    deleteFile = (id: number): Promise<any> =>
        SurveyRequest.post('delete/file', {
            id
        });

    disqualSurvey = async (): Promise<void> => {
        const resp = await SurveyRequest.post('disqual/response');
        const { code } = resp;

        if (code !== ApiRespCode.OK_CODE) {
            throw resp;
        }
    };

    getNested = async (
        question_id: number,
        level: number,
        parent_id: number
    ): Promise<{ items: Array<NestedItem> }> => {
        const resp = await SurveyRequest.post('show/nested', {
            question_id,
            level,
            parent_id
        });
        const { code, items } = resp;

        if (code !== ApiRespCode.OK_CODE) {
            throw resp;
        }

        return {
            items: (items as Array<AnyObject>).map(mapNested)
        };
    };

    getResidences = async (level: number, parent: number): Promise<Array<IResidenceModel>> => {
        const resp = await SurveyRequest.post('list/residence', {
            level,
            parent
        });
        const { rows = [], code } = resp;

        if (code !== ApiRespCode.OK_CODE) {
            throw resp;
        }

        return prepareResidences(rows, parent);
    };

    getSurveyData = async (): Promise<any> => {
        const resp = await SurveyRequest.post('list/page_question');
        const { code, questions = [], pages = [] } = resp;

        if (code !== ApiRespCode.OK_CODE) {
            throw resp;
        }

        return {
            pages: mapPages(pages),
            questions: mapQuestions(questions)
        };
    };

    sendAllAnswers = async (answers: { question: number; data: Array<any> }): Promise<void> => {
        const resp = await SurveyRequest.post('add/all_detail', { data: answers });
        const { code } = resp;

        if (code !== ApiRespCode.OK_CODE) {
            throw resp;
        }

        return resp;
    };

    sendAnswers = async (data: any): Promise<any> => {
        const resp = await SurveyRequest.post('add/detail', { details: data });
        const { code } = resp;

        if (code !== ApiRespCode.OK_CODE) {
            throw resp;
        }

        return resp;
    };

    sendAudio = async (surveyId: number, data: any): Promise<any> => {
        const body = new FormData();

        body.append('upfile', data);

        const resp = await SurveyRequest.post('upload/response/audio', body, {
            'Access-Control-Request-Headers': 'Content-Type,X-Authorization',
            'Access-Control-Request-Method': 'POST'
        });
        const { code } = resp;

        if (code !== ApiRespCode.OK_CODE) {
            throw resp;
        }

        return resp;
    };

    sendOfflineAnswers = async (survey): Promise<any> => {
        const records = await getOfflineAnswers();
        const alias = getUrlSearchParam('alias') || undefined;
        const status = survey.status || undefined;
        const sent = 0;

        for await (const [uid, { answers, start, stop }] of records) {
            try {
                const resp = await SurveyRequest.post('add/responses', {
                    responses: [
                        {
                            response_id: uid,
                            survey: survey.info.id,
                            start_date: start,
                            stop_date: stop,
                            status,
                            alias,
                            source: 'offline',
                            details: answers
                        }
                    ]
                });

                if (resp.code !== ApiRespCode.OK_CODE) {
                    throw resp;
                }

                await updateOfflineStats((prev) => ({
                    ...prev,
                    sent: (prev?.sent || 0) + 1
                }));

                await deleteOfflineAnswer(uid);
            } catch (e) {
                console.error(e);
            }
        }

        return sent;
    };

    setToken = (token: string): void => {
        SurveyRequest.token = token;
    };

    startSurvey = (
        rewrite: string,
        token: string,
        params: AnyObject,
        lktoken: string,
        search: string
    ): Promise<any> => {
        return SurveyRequest.post('start/response', {
            rewrite,
            ...params,
            offline: isOfflineMode(),
            query_params: search,
            token: token || undefined,
            lktoken: lktoken || undefined,
            fingerprint
        });
    };

    stopSurvey = async (): Promise<any> => {
        const resp = await SurveyRequest.post('stop/response');
        const { code } = resp;

        if (code !== ApiRespCode.OK_CODE) {
            throw resp;
        }

        return resp;
    };

    uploadFiles = async (surveyId: number, files: Array<File>): Promise<Array<AnyObject>> => {
        const newFiles = [];

        for (const f of files) {
            newFiles.push(await uploadFile(surveyId, f));
        }

        return newFiles;
    };

    uploadFile = async (files: Array<File>, folder?: string): Promise<Array<AnyObject>> => {
        const newFiles = [];

        for (const f of files) {
            newFiles.push(await uploadUserFile(f, folder));
        }

        return newFiles;
    };
}

export type SavedRecord = ISurveyQuestionModel & { ts: number };
export type SavedQuestions = Record<number, SavedRecord>;

export const surveyApi = new SurveyAPI();

export const addDetails = ({ comment, data, question }: AnswersRequest): void => {
    const ts = +new Date();
    LocalStorage.append<SavedQuestions>('question_details', {
        [question]: {
            ts,
            // @ts-ignore
            question,
            data,
            comment
        }
    });
    startWaiting();
};

export const clearSentDetails = (before: number): void => {
    LocalStorage.filter<SavedRecord>('question_details', (item) => {
        if (item.ts) {
            return item.ts > before;
        }

        return false;
    });
};

export const detailsPending = (): boolean => {
    const queue = LocalStorage.get<SavedQuestions>('question_details');
    return queue && Object.keys(queue).length > 0;
};

const SEND_DETAILS_INTERVAL = 10 * 1000;
let sendDetailsInterval = null;

let lastRequest: Promise<any> = null;

// TODO move to service worker
// TODO move timer to survey store
// TODO merge with surveyApi

const startWaiting = (): void => {
    if (!sendDetailsInterval && lastRequest === null) {
        sendDetailsInterval = setTimeout(sendDetails, SEND_DETAILS_INTERVAL);
    }
};

export const stopWaiting = (): void => {
    clearTimeout(sendDetailsInterval);
    sendDetailsInterval = null;
};

let sendReq = 0;

export const sendSavedDetails = async (): Promise<any> => {
    const r = ++sendReq;
    stopWaiting();

    let details = LocalStorage.get<SavedQuestions>('question_details');
    details = details || {};
    const values = Object.values(details);

    if (values.length < 1) return true;

    const data = values.reduce((acc, q) => (acc.push(q), acc), []);
    const now = +new Date();
    const resp = await surveyApi.sendAnswers(data);

    clearSentDetails(now);

    return Promise.resolve(resp);
};

const sendDetails = (): void => {
    if (lastRequest !== null) return;

    try {
        lastRequest = sendSavedDetails();
    } catch (e) {
        console.error(e);
    }

    lastRequest
        .then(() => {
            startWaiting();
        })
        .finally(() => {
            lastRequest = null;
        });
};

const offlineAnswersStore = isOfflineMode() ? createStore('offline-answers-db', 'answers') : null;
const offlineStatStore = isOfflineMode() ? createStore('offline-stat', 'stat') : null;

export const saveAnswersOffline = async (answers: Array<AnswersRequest>, survey: ISurveyStore): Promise<any> =>
    Promise.all([
        update(
            nanoid(),
            (_prev) => ({
                answers,
                start: survey.startDatetime.toISOString(),
                stop: survey.stopDatetime.toISOString()
            }),
            offlineAnswersStore
        ),
        updateOfflineStats((prev) => ({
            ...prev,
            total: (prev?.total || 0) + 1
        }))
    ]);

export const getOfflineAnswers = async (): Promise<Array<any>> => await entries(offlineAnswersStore);

export const deleteOfflineAnswer = async (id: string): Promise<void> => await del(id, offlineAnswersStore);

export const getOfflineAnswersCount = async (): Promise<number> => (await keys(offlineAnswersStore)).length;

export interface OfflineStats {
    sent: number;
    total: number;
}

export const getOfflineStats = async (): Promise<OfflineStats> => {
    const stat = await get('stat', offlineStatStore);
    return {
        sent: stat?.sent || 0,
        total: stat?.total || 0
    };
};

export const updateOfflineStats = async (cb: (prev: OfflineStats) => OfflineStats): Promise<void> =>
    update(
        'stat',
        (prev) => {
            const next = cb(prev);
            return { ...prev, ...next };
        },
        offlineStatStore
    );

// TODO apply for only upload requests
isBrowser &&
    window.addEventListener('beforeunload', (event) => {
        console.log(hasPendingRequests(), detailsPending());
        if (hasPendingRequests() || detailsPending()) {
            stopWaiting();
            try {
                lastRequest = sendSavedDetails();
            } catch (e) {
                console.error(e);
            }
            event.preventDefault();
            event.returnValue = 'Данные еще не отправлены. Уйти со страницы?';
        }
    });

isBrowser &&
    window.addEventListener('online', () => {
        if (!isOfflineMode() && detailsPending()) {
            startWaiting();
        }
    });

isBrowser &&
    window.addEventListener('offline', () => {
        stopWaiting();
    });

if (isBrowser && !isOfflineMode() && detailsPending()) {
    startWaiting();
}

import type { Instance } from 'mobx-state-tree';
import { types } from 'mobx-state-tree';

import { Enumeration } from '@webapp/common/resources/mst-types/nenumerations';

import { SurveyPageModel } from '../page';
import { SurveyQuestionModel } from '../question';

export enum SurveyBundleType {
    REDIRECT_URL = 'redirect_url',
    REDIRECT_SURVEY = 'redirect_survey',
    TEXT = 'text',
    QUESTIONS = 'questions'
}

export enum SurveyBundleFinishType {
    DISQUAL = 'disqual',
    STOP = 'stop',
    STOP_BY_TIMER = 'stopByTimer',
    TEXT = 'TEXT'
}

export const SurveyBundleModel = types
    .model('survey_bundle', {
        type: types.maybeNull(Enumeration(SurveyBundleType)),
        finishType: types.maybeNull(Enumeration(SurveyBundleFinishType)),
        random: types.optional(types.boolean, false),
        text: types.maybeNull(types.string),
        questions: types.optional(types.array(types.safeReference(SurveyQuestionModel)), []),
        page: types.safeReference(SurveyPageModel),
        redirect: types.maybeNull(types.union(types.string, types.number))
    })
    .views((self) => ({
        get isValid() {
            return self.questions.every((q) => !q.invalid && !q.commentInvalid);
        }
    }))
    .actions((self) => ({
        stopTimers() {
            self.questions.forEach((q) => q.stopTimer());
        },
        validate() {
            self.questions.map((q) => q.validate());
        }
    }));

export type ISurveyBundleModel = Instance<typeof SurveyBundleModel>;

import type { Instance } from 'mobx-state-tree';
import { types } from 'mobx-state-tree';

export const ResidenceModel = types.model('residence', {
    id: types.optional(types.identifierNumber, 0),
    name: types.optional(types.string, ''),
    level: types.optional(types.number, 1),
    parent: types.maybeNull(types.number)
});

export type IResidenceModel = Instance<typeof ResidenceModel>;

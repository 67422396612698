import { castToSnapshot } from 'mobx-state-tree';

import { pickIds, shuffleArray } from '@webapp/common/lib/utils';
import type { ISurveyPageModel } from '@webapp/common/resources/mst-survey/page';
import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import { QuestionType, withClickReply } from '@webapp/common/resources/survey';

import type { ISurveyBundleModel } from '../bundle';
import { SurveyBundleType } from '../bundle';

import { filterBundlesQuestionsByLogic } from './logic';

const randomizePage = (
    page: ISurveyPageModel,
    pageQuestions: Array<ISurveyQuestionModel>
): Array<ISurveyQuestionModel> => {
    // TODO ?? what this for
    const pickOrdered = (ids: Array<number>): Array<ISurveyQuestionModel> =>
        ids.map((id, idx) => {
            const q = pageQuestions.find((q) => q.id === id);
            q.setOrder(idx + 1);
            return q;
        });

    if (page.shuffleOrder.length > 0) {
        return pickOrdered(page.shuffleOrder);
    }

    const alwaysLast = pageQuestions.filter(({ type }) => QuestionType.AGREEMENT === type);
    const regular = pageQuestions.filter(({ type }) => QuestionType.AGREEMENT !== type);
    const ordered = pickOrdered(shuffleArray(pickIds(regular))).concat(alwaysLast);
    page.shuffleOrder = pickIds(ordered) as any;

    return ordered;
};

const packOneByOne = (
    page: ISurveyPageModel,
    pageQuestions: Array<ISurveyQuestionModel>,
    bundles: Array<ISurveyBundleModel>
): Array<ISurveyBundleModel> => {
    let questions = pageQuestions;

    if (page.random) {
        questions = randomizePage(page, pageQuestions);
    }

    questions.forEach((question) => {
        bundles.push(
            castToSnapshot({
                type: SurveyBundleType.QUESTIONS,
                random: page.random,
                finishType: null,
                page,
                questions: [question]
            })
        );
    });

    return bundles;
};

const packRandom = (
    page: ISurveyPageModel,
    pageQuestions: Array<ISurveyQuestionModel>,
    bundles: Array<ISurveyBundleModel>
): Array<ISurveyBundleModel> => {
    const questions = randomizePage(page, pageQuestions);

    bundles.push(
        castToSnapshot({
            random: true,
            type: SurveyBundleType.QUESTIONS,
            page,
            questions
        })
    );

    return bundles;
};

const packOrdinary = (
    page: ISurveyPageModel,
    pageQuestions: Array<ISurveyQuestionModel>,
    bundles: Array<ISurveyBundleModel>
): Array<ISurveyBundleModel> => {
    let split = true;
    let prevClickToReply = false;

    pageQuestions.forEach((q) => {
        const clickReply = q.survey.info.params.other.clickReply && withClickReply(q.type);

        if (clickReply || split || prevClickToReply) {
            bundles.push(
                castToSnapshot({
                    random: false,
                    type: SurveyBundleType.QUESTIONS,
                    page,
                    questions: [q]
                })
            );

            if (split) {
                split = false;
            }
        } else {
            bundles[bundles.length - 1].questions.push(q);
        }

        prevClickToReply = clickReply;
    });

    return bundles;
};

export const packBundles = (
    pages: Array<ISurveyPageModel>,
    questions: Array<ISurveyQuestionModel>
): Array<ISurveyBundleModel> => {
    let bundles = pages.reduce((bundles, page) => {
        const pageQuestions = questions.filter((q) => q.page === page.id);

        if (page.onebyone) {
            return packOneByOne(page, pageQuestions, bundles);
        } else if (page.random) {
            return packRandom(page, pageQuestions, bundles);
        } else {
            return packOrdinary(page, pageQuestions, bundles);
        }
    }, []);

    bundles = filterBundlesQuestionsByLogic(bundles);

    return bundles;
};

export const splitByTimerOn = (timerOn: number, bundles: Array<ISurveyBundleModel>): Array<ISurveyBundleModel> => {
    const bundleWithTimerOnQuestion = bundles.find(({ questions }) => questions.find(({ id }) => id === timerOn));

    if (!bundleWithTimerOnQuestion) {
        console.error(`splitByTimerOn: question ${timerOn} no found`);
        return bundles; // skip for non-existed question
    }

    const { questions } = bundleWithTimerOnQuestion;

    if (questions.length < 2) {
        return bundles;
    }

    const newBundles: Array<ISurveyBundleModel> = [];
    for (const b of bundles) {
        if (b !== bundleWithTimerOnQuestion) {
            newBundles.push(b);
            continue;
        }

        const timerQuestionIdx = questions.findIndex(({ id }) => id === timerOn);

        newBundles.push({
            ...bundleWithTimerOnQuestion,
            questions: questions && (questions.slice(0, timerQuestionIdx + 1) as any)
        });

        if (questions[timerQuestionIdx + 1]) {
            newBundles.push({
                ...bundleWithTimerOnQuestion,
                questions: questions.slice(timerQuestionIdx + 1) as any
            });
        }
    }

    return newBundles;
};

export const splitByQuestionsWithTimer = (bundles: Array<ISurveyBundleModel>): Array<ISurveyBundleModel> => {
    const newBundles: Array<ISurveyBundleModel> = [];

    const add = (page, questions): void => {
        newBundles.push(
            castToSnapshot({
                type: SurveyBundleType.QUESTIONS,
                page,
                questions
            })
        );
    };

    for (const b of bundles) {
        const { page } = b;
        let { questions } = b;
        let questionWithTimerIdx = questions.findIndex(({ params: { timer } }) => !!timer);

        if (questionWithTimerIdx < 0) {
            newBundles.push(b);
            continue;
        }

        while ((questionWithTimerIdx = questions.findIndex(({ params: { timer } }) => !!timer)) > -1) {
            const questionsBefore = questions.slice(0, questionWithTimerIdx);
            const questionWithTimer = questions.slice(questionWithTimerIdx, questionWithTimerIdx + 1);

            questions = questions.slice(questionWithTimerIdx + 1) as any;

            if (questionsBefore.length > 0) {
                add(page, questionsBefore);
            }

            add(page, questionWithTimer);
        }

        if (questions.length > 0) {
            add(page, questions);
        }
    }

    return newBundles;
};

export const calcCurrentBundleIndex = (bundles: Array<ISurveyBundleModel>): number => {
    let currentBundleIndex = 0;
    for (const { questions } of bundles) {
        const validAndAnswered = questions.every((q) => q.wasChanged && !q.invalid);
        if (validAndAnswered) {
            currentBundleIndex++;
        } else {
            break;
        }
    }

    return Math.min(currentBundleIndex, bundles.length - 1); // TODO wtf
};

import type { Instance } from 'mobx-state-tree';
import { types } from 'mobx-state-tree';

import { Enumeration } from '@webapp/common/resources/mst-types/nenumerations';

import { TestCheckStatus } from '../survey';

import { SurveyQuestionResponseModel } from './question_response';

export const SurveyQuestionAnswerModel = types
    .model('survey_question_answer', {
        id: types.identifierNumber,
        value: types.array(types.maybeNull(types.union(types.string, types.number, types.boolean))),
        type: types.maybeNull(types.number),
        exception: types.optional(types.boolean, false),
        response: types.optional(
            types.late(() => SurveyQuestionResponseModel),
            {}
        ),
        testCheckStatus: types.optional(Enumeration(TestCheckStatus), TestCheckStatus.NOT_CHECKED),
        file: types.maybeNull(
            types.model({
                id: types.maybeNull(types.number),
                path: types.maybeNull(types.string),
                name: types.maybeNull(types.string),
                width: types.maybeNull(types.number),
                height: types.maybeNull(types.number),
                mimeType: types.maybeNull(types.string)
            })
        ),
        valid: types.optional(types.boolean, true),
        answeredCount: types.optional(types.number, 0)
    })
    .actions((self) => ({
        setValid(valid: boolean) {
            self.valid = valid;
        },
        setAnsweredCount(answeredCount: number) {
            self.answeredCount = answeredCount;
        },
        setTestCheckStatus(testCheckStatus: TestCheckStatus) {
            self.testCheckStatus = testCheckStatus;
        }
    }));

export type ISurveyQuestionAnswerModel = Instance<typeof SurveyQuestionAnswerModel>;

import type { Instance } from 'mobx-state-tree';
import { types } from 'mobx-state-tree';

import { DataTimeArray, TimeFormatArray } from '@webapp/common/lib/date';
import { Enumeration } from '@webapp/common/resources/mst-types/nenumerations';
import { SmilePackName } from '@webapp/ui/lib/smile-image';

import { DropdownType } from '../survey';

export enum ImageTextPosition {
    UNDER = 'under',
    ABOVE = 'above'
}

export const SurveyQuestionParamsModel = types.model('survey_question_params', {
    timer: types.optional(types.boolean, false),
    hours: types.optional(types.number, 0),
    minutes: types.optional(types.number, 0),
    seconds: types.optional(types.number, 0),

    name: types.maybeNull(types.boolean),

    help: types.maybeNull(types.boolean),
    helpText: types.maybeNull(types.string),

    required: types.maybeNull(types.boolean),

    comment: types.maybeNull(types.boolean),
    commentText: types.maybeNull(types.string),

    cantRate: types.maybeNull(types.boolean),
    cantRateText: types.maybeNull(types.string),

    commentRequired: types.maybeNull(types.boolean),

    randomOrder: types.maybeNull(types.boolean),

    excludeSelectedAnswer: types.maybeNull(types.boolean),

    textBlock: types.maybeNull(types.string),

    agreementLeftLabel: types.maybeNull(types.string),
    agreementRightLabel: types.maybeNull(types.string),
    agreementLink: types.maybeNull(types.string),
    agreementText: types.maybeNull(types.string),

    dateType: types.maybeNull(types.enumeration('dateType', DataTimeArray)),
    dateFormat: types.maybeNull(
        types.model({
            label: types.maybeNull(types.string),
            format: types.maybeNull(types.string)
        })
    ),
    timeFormat: types.maybeNull(types.enumeration('timeFormat', TimeFormatArray)),

    minLength: types.maybeNull(types.number),
    maxLength: types.maybeNull(types.number),

    phoneFormatCountry: types.maybeNull(types.string),
    phoneFormatNumber: types.maybeNull(types.string),

    // TODO: implement fileType
    fileAmount: types.maybeNull(types.number),
    uploadButtonName: types.maybeNull(types.string),
    fileLimitExt: types.maybeNull(types.array(types.string)),

    answerType: types.maybeNull(types.enumeration('any', ['any', 'number'])),

    size: types.maybeNull(types.number),
    amount: types.maybeNull(types.number),
    color: types.maybeNull(types.string),

    npsLeftLabel: types.maybeNull(types.string),
    npsRightLabel: types.maybeNull(types.string),
    npsColors: types.maybeNull(types.array(types.string)),

    distributeSurplus: types.maybeNull(types.boolean),
    distributeLabel: types.maybeNull(types.string),
    distributeScore: types.maybeNull(types.union(types.number, types.string)),
    distributeUnit: types.maybeNull(types.string),

    dropdownListType: types.maybeNull(Enumeration(DropdownType)),

    answers: types.maybeNull(types.array(types.string)),

    imageColumns: types.maybeNull(types.number),
    questionColumns: types.maybeNull(types.number),
    imageTextPosition: types.maybeNull(Enumeration(ImageTextPosition)),

    smileType: types.maybeNull(types.string),
    smilePack: types.maybeNull(Enumeration(SmilePackName)),
    matrixType: types.maybeNull(types.string),
    matrixNumbersColor: types.maybeNull(types.array(types.string)),
    scaleType: types.maybeNull(types.enumeration('custom', ['number', 'custom'])),
    scaleStartDia: types.maybeNull(types.number),
    scaleEndDia: types.maybeNull(types.number),
    scaleFormat: types.maybeNull(types.enumeration('numbers', ['numbers', 'numbers-with-borders', 'slider'])),
    scaleColors: types.maybeNull(types.array(types.string)),
    minAmount: types.maybeNull(types.number),
    maxAmount: types.maybeNull(types.number),
    csiFormat: types.maybeNull(types.string)
});

export type ISurveyQuestionParamsModel = Instance<typeof SurveyQuestionParamsModel>;

import type { Instance } from 'mobx-state-tree';
import { types } from 'mobx-state-tree';

import { SurveyInfoDesignModel } from '@webapp/common/resources/mst-survey/info_design';
// eslint-disable-next-line import/namespace
import { DetailsArray, SurveyInfoParamsModel } from '@webapp/common/resources/mst-survey/info_params';

export const SurveyInfoModel = types.model('survey_info', {
    code: types.optional(types.number, 0),
    id: types.optional(types.number, 0),
    title: types.optional(types.string, ''),
    template: types.optional(types.boolean, false),
    hideFooter: types.optional(types.boolean, true),
    params: types.optional(SurveyInfoParamsModel, {}),
    design: types.optional(SurveyInfoDesignModel, {}),
    details: types.maybeNull(types.array(DetailsArray))
});

export type ISurveyInfo = Instance<typeof SurveyInfoModel>;

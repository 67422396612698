import cloneDeep from 'lodash/cloneDeep';
import { initialPage } from 'resources/common';

import { parseJSON, UnescapeHtml } from '@webapp/common/lib/utils';
import type { ISurveyInfo } from '@webapp/common/resources/mst-survey/info';
import type {
    IParamsMainModel,
    IParamsShareModel,
    IParamsTestsMode
} from '@webapp/common/resources/mst-survey/info_params';
import type { ISurveyPageModel } from '@webapp/common/resources/mst-survey/page';
import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import type { ISurveyQuestionLogicModel } from '@webapp/common/resources/mst-survey/question_logic';
import type { ISurveyQuestionParamsModel } from '@webapp/common/resources/mst-survey/question_params';
import type { QuestionType } from '@webapp/common/resources/survey';

export const mapInfoParamsMain = (data: any = {}): IParamsMainModel => ({
    ...data,
    timer: data.timer || false,
    timerHours: parseInt(data.timerHours, 10) || 0,
    timerMinutes: parseInt(data.timerMinutes, 10) || 0,
    timerSeconds: parseInt(data.timerSeconds, 10) || 0,
    disabledSurveyText: data.disabledSurveyText ? UnescapeHtml(data.disabledSurveyText) : null,
    surveyWasBlocked: data.surveyWasBlocked ? UnescapeHtml(data.surveyWasBlocked) : null,
    completePageText: data.completePageText ? UnescapeHtml(data.completePageText) : null,
    completeSurvey: data.completeSurvey,
    completeSurveyUrl: data.completeSurveyUrl,
    welcomePageText: data.welcomePageText ? UnescapeHtml(data.welcomePageText) : null
});

export const mapInfoParamsTests = (data: any = {}): IParamsTestsMode => ({
    maxScoreAmount: isNaN(parseFloat(data.maxScoreAmount)) ? null : parseFloat(data.maxScoreAmount),
    passingScore: isNaN(parseFloat(data.passingScore)) ? null : parseFloat(data.passingScore),
    testResultLabel: typeof data.testResultLabel === 'string' ? UnescapeHtml(data.testResultLabel) : null,
    passingScoreLabel: typeof data.passingScoreLabel === 'string' ? UnescapeHtml(data.passingScoreLabel) : null,
    testPassed: typeof data.testPassed === 'string' ? UnescapeHtml(data.testPassed) : null,
    testNotPassed: typeof data.testNotPassed === 'string' ? UnescapeHtml(data.testNotPassed) : null,
    scoreLabel: typeof data.scoreLabel === 'string' ? UnescapeHtml(data.scoreLabel) : null,
    copyUrlParams: typeof data.copyUrlParams === 'boolean' ? data.copyUrlParams : false,
    textDiapasons: Array.isArray(data.textDiapasons) ? data.textDiapasons : []
});

export const mapInfoParamsShare = (data: any = {}): IParamsShareModel => ({
    download: Boolean(data && data.download),
    filters: Boolean(data && data.filters),
    answers: Boolean(data && data.answers),
    reports: Boolean(data && data.reports)
});

export const mapInfoDetails = (data: any = {}) => ({
    answer_bool: data.ANSWER_BOOL,
    answer_group_id: data.ANSWER_GROUP_ID,
    answer_id: data.ANSWER_ID,
    answer_int: data.ANSWER_INT,
    answer_str: data.ANSWER_STR,
    answer_text: data.ANSWER_TEXT,
    comment: data.COMMENT,
    expired: data.EXPIRED,
    file_id: data.FILE_ID,
    question_id: data.QUESTION_ID,
    cant_rate: data.CANT_RATE
});

export const mapInfo = (data: any = {}): ISurveyInfo => {
    const { $addons, $params, ID, template, TITLE } = data.rows;

    return {
        code: data?.code,
        id: ID,
        title: TITLE,
        template,
        hideFooter: Boolean($addons && $addons.hideFooter),
        params: {
            alert: parseJSON($params.alert),
            main: mapInfoParamsMain(parseJSON($params.main)),
            other: parseJSON($params.params),
            tests: mapInfoParamsTests(parseJSON($params.tests)),
            share: mapInfoParamsShare(parseJSON($params.share))
        },
        design: {
            brand: parseJSON($params.brand),
            buttons: parseJSON($params.buttons),
            css: parseJSON($params.css || '{}'),
            fonts: parseJSON($params.fonts),
            view: parseJSON($params.view)
        },
        details: data.details?.map(mapInfoDetails)
    };
};

export const mapQuestionAnswer = (data: any = {}): any => {
    let value = [data.ANSWER];
    const withFile = data.FILE_PATH || data.FILE_ID;

    try {
        value = JSON.parse(data.ANSWER);
    } catch (e) {
        console.error(e);
    }

    return {
        id: data.ID,
        value,
        type: data.TYPE,
        exception: Boolean(data.EXCEPTION),
        file: withFile && {
            id: data.FILE_ID,
            path: data.FILE_PATH,
            width: data.IMAGE_WIDTH,
            height: data.IMAGE_HEIGHT,
            mimeType: data.MIME_TYPE
        }
    };
};

export const mapQuestionGroup = ({
    ANSWERS,
    EXCEPTION,
    ID,
    NAME,
    TYPE,
    ORDER,
    NUMERIC_VALUE
}: {
    ANSWERS: string;
    EXCEPTION: number;
    ID: number;
    NAME: string;
    TYPE: string;
    ORDER: number;
    NUMERIC_VALUE: number;
}): any => {
    let answers: Array<string> = [];

    try {
        answers = ANSWERS ? JSON.parse(ANSWERS) : answers;
    } catch (e) {
        console.error(e);
    }

    return {
        id: ID,
        name: NAME,
        exception: Boolean(EXCEPTION),
        type: TYPE && Number(TYPE), // TODO fix backend, pass number  core#121
        answers,
        order: ORDER,
        numericValue: NUMERIC_VALUE
    };
};

export const mapQuestionLogic = (data: any = {}): ISurveyQuestionLogicModel => ({
    id: data.ID,
    action: data.TRANSITION_ACTION,
    type: data.TYPE,
    transition: data.GOAL,
    transitionType: data.TRANSITION_TYPE,
    boolFunc: data.BOOL_FUNC,
    toQuestion: data.NEXT_QUESTION,
    toQuestions: data.NEXT_QUESTIONS ? parseJSON(data.NEXT_QUESTIONS, []) : ([] as any),
    toPages: data.NEXT_PAGES ? parseJSON(data.NEXT_PAGES, []) : ([] as any),
    toSurvey: data.TO_SURVEY,
    toSurveyUrl: data.REWRITE,
    toPage: data.NEXT_PAGE,
    toWebsite: data.TO_WEB,
    completeText: data.COMPLETE_TEXT ? UnescapeHtml(data.COMPLETE_TEXT) : null,
    disqualText: data.BREAK_TEXT ? UnescapeHtml(data.BREAK_TEXT) : null,
    params: data.PARAMS ? parseJSON(data.PARAMS, []) : undefined
});

export const mapQuestion = ({
    answers,
    groups,
    ID,
    logics,
    PAGE,
    parameters,
    QUESTION,
    QUESTION_TYPE_ID
}: any = {}): Partial<ISurveyQuestionModel> => {
    const params = parseJSON<ISurveyQuestionParamsModel>(parameters.params, {});
    // TODO webapp#583
    const minLength = !!params.minLength ? parseInt(params.minLength as any, 10) || 0 : undefined;
    const maxLength = !!params.maxLength ? parseInt(params.maxLength as any, 10) || 0 : undefined;

    return {
        id: ID,
        title: QUESTION ? UnescapeHtml(QUESTION) : '',
        page: PAGE,
        type: QUESTION_TYPE_ID as QuestionType,
        answers: answers.map(mapQuestionAnswer),
        groups: groups.map(mapQuestionGroup),
        logics: logics.map(mapQuestionLogic),
        params: {
            ...params,
            minLength,
            maxLength,
            timer: params.timer || false,
            hours: parseInt(params.hours as any, 10) || 0,
            minutes: parseInt(params.minutes as any, 10) || 0,
            seconds: parseInt(params.seconds as any, 10) || 0,
            helpText: params.helpText ? UnescapeHtml(params.helpText) : null,
            agreementLink: params.agreementLink ? UnescapeHtml(params.agreementLink) : null,
            agreementLeftLabel: params.agreementLeftLabel ? UnescapeHtml(params.agreementLeftLabel) : null,
            agreementRightLabel: params.agreementRightLabel ? UnescapeHtml(params.agreementRightLabel) : null,
            textBlock: params.textBlock ? UnescapeHtml(params.textBlock) : null,
            amount: params.amount && Number(params.amount),
            size: params.size && Number(params.size)
        }
    };
};

export const mapQuestions = (questions: any = {}): Array<ISurveyQuestionModel> => questions.map(mapQuestion);

export const mapPage = ({ ID, QUESTION_RANDOM_ORDERING, QUESTION_SP, TITLE }: any = {}): Partial<ISurveyPageModel> => ({
    ...cloneDeep(initialPage),
    id: ID,
    title: TITLE || '',
    onebyone: Boolean(QUESTION_SP),
    random: Boolean(QUESTION_RANDOM_ORDERING)
});

export const mapPages = (pages: any = {}): Array<ISurveyPageModel> => pages.map(mapPage);

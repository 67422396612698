import { enableStaticRendering } from 'mobx-react-lite';
import { applySnapshot, types } from 'mobx-state-tree';
import type { Instance, SnapshotIn } from 'mobx-state-tree';
import { createContext, useContext } from 'react';

enableStaticRendering(isServer);

import { isBrowser, isServer } from '@webapp/common/lib/const';
import { LocalStorage } from '@webapp/common/lib/storage';
import { extractSubdomain } from '@webapp/common/lib/utils';
import { SurveyStore } from '@webapp/common/resources/mst-survey/survey/survey';

export const Store = types.model({
    survey: types.optional(SurveyStore, {}),
    devTools: types.optional(
        types
            .model('devTools', {
                enabled: types.optional(types.boolean, false)
            })
            .actions((self) => ({
                setEnabled(enabled: boolean) {
                    self.enabled = enabled;
                }
            })),
        {}
    )
});

export type StoreInstance = Instance<typeof Store>;
export type StoreSnapshotIn = SnapshotIn<typeof Store>;

export let store: StoreInstance = null;

export const initializeStore = (snapshot: StoreSnapshotIn = null): StoreInstance => {
    if (isServer || store === null) {
        store = Store.create();
    }

    if (snapshot) {
        applySnapshot(store, snapshot);
    }

    if (isBrowser) {
        // TODO improve stored settings handling
        const domain = extractSubdomain(window.location.host);
        const individualTries = LocalStorage.get<number>(`${domain}-invalid-password`) || 0;
        void store.survey.setIndividualTries(individualTries);
    }

    return store;
};

const StoreContext = createContext<StoreInstance | null>(null);

export const StoreProvider = StoreContext.Provider;

export const useStore = (): StoreInstance => useContext(StoreContext);

import { VERSION } from '@webapp/common/conf';

export const DevIndicator: FC<{
    data?: Array<string | number>;
}> = ({ data = [] }) => {
    const ids = window.location.pathname.split('/').map(Number).filter(Boolean);

    return (
        <div
            style={{
                position: 'fixed',
                bottom: '20px',
                left: '20px',
                color: 'red',
                fontWeight: 'bold',
                zIndex: 1000
            }}
        >
            <div>DEV</div>
            <div>
                {ids.map((id) => (
                    <div key={id}>
                        {id}
                        <br />
                    </div>
                ))}
            </div>
            <div>
                {data.map((a) => (
                    <div key={a}>
                        {a}
                        <br />
                    </div>
                ))}
            </div>
            <div>{VERSION}</div>
        </div>
    );
};

import type { Instance } from 'mobx-state-tree';
import { types } from 'mobx-state-tree';

export const SurveyPageModel = types.model('survey_page', {
    id: types.identifierNumber,
    title: types.optional(types.string, ''),
    onebyone: types.optional(types.boolean, false),
    random: types.optional(types.boolean, false),
    shuffleOrder: types.optional(types.array(types.number), [])
});

export type ISurveyPageModel = Instance<typeof SurveyPageModel>;

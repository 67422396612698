import { isBrowser, isPreview } from '@webapp/common/lib/const';
import { getUrlSearchParam } from '@webapp/common/lib/utils';

const uriParamsWhiteList = ['alias', 'source', 'referer', 'id'];

export const getSurveyParams = (): AnyObject | undefined => {
    const urlParams = new URLSearchParams(window.location.search);
    const tmp = {};
    let params;

    for (const p of uriParamsWhiteList) {
        tmp[p] = urlParams.get(p) || undefined;
    }
    if (Object.keys(tmp).length > 0) {
        params = tmp;
    }

    return params;
};

export const isOfflineMode = (): boolean => {
    if (!isBrowser) return false;
    const { pathname } = new URL(document.location.href);
    return pathname === '/offline';
};

export const getLkToken = (): string | undefined => {
    // TODO not used at backend. purge??
    let lktoken;

    if (isPreview) {
        lktoken = getUrlSearchParam('lktoken') || undefined;
    }

    return lktoken;
};

import dayjs from 'dayjs';
import type { Duration } from 'dayjs/plugin/duration';
import { types } from 'mobx-state-tree';

export const DurationType = types.custom<Duration, Duration>({
    name: 'Duration',
    isTargetType(value) {
        return dayjs.isDuration(value);
    },
    getValidationMessage(value: Duration) {
        if (dayjs.isDuration(value)) return '';

        return `${value} is not a Duration`;
    },
    fromSnapshot(value) {
        if (dayjs.isDuration(value)) {
            return value;
        }

        return dayjs.duration(value);
    },
    toSnapshot(value: any) {
        value = value === 'P0D' ? 0 : value;
        if (dayjs.isDuration(value)) {
            return value;
        }

        return dayjs.duration(value);
    }
});

export const DurationModel = types.optional(types.maybeNull(DurationType), () => null);
